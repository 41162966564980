import config from 'config';

const userRoleType = {
  ABATTOIR: 'abattoir',
  AICENTRE: 'aiCentre',
  ASSEMBLY: 'assembly',
  BACKOFFICE: 'backoffice',
  COLLECTION: 'collection',
  COMMON: 'common',
  GENERIC: 'generic',
  KEEPER: 'keeper',
  MARKET: 'market',
  OTHER: 'other',
  PORT: 'port',
  QUARANTINE: 'quarantine',
  SHOW: 'show',
  SLAUGHTERMARKET: 'slaughtermarket',
  STOREMARKET: 'storemarket',
  UNKNOWN: config.UNKNOWN,
  VET: 'vet',
  VETUSER: 'vetuser',
  RCVSVET: 'RCVSVet'
};

export default userRoleType;
