import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import BackgroundPage1 from 'templates/page1.png';
import BackgroundPage2 from 'templates/page2.png';
import SuplimentaryForm from 'templates/suplimentary_form.png';
import SuplimentaryFormLast from 'templates/suplimentary_form_last.png';
import FciAnimalForm from 'templates/fci_animals_form.png';
import FciAnimalFormLast from 'templates/fci_animals_form_last.png';

import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import moment from 'moment';
const sprintf = require('util').format;

const pdfProperties = {
  page: {
    flexDirection: 'column',
    fontSize: '11px'
  },
  row: {
    flexGrow: 1
  },
  line: {
    flexDirection: 'row',
    margin: 3,
    padding: 2
  },
  section: {
    flexDirection: 'row',
    margin: 6,
    padding: 3,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black'
  },
  section2: {
    margin: 6,
    padding: 3,
    flexGrow: 1,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'black'
  },
  image: {
    margin: 0,
    padding: 0,
    top: 0,
    left: 0
  },
  exemptions: {
    border: '1px solid red',
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
    left: '555px',
    height: '14px',
    width: '14px'
  },
  exemptionsText: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
    left: '315px',
    width: '225px',
    wordWrap: 'normal',
    textAlign: 'right'
  },
  departDetailSection: {
    position: 'absolute',
    top: '285px',
    width: '47%',
    left: '50%',
    height: '250px'
  },
  departureLocation: {
    position: 'absolute',
    top: '70px',
    width: '100%',
    height: '435px'
  },
  processingData: {
    position: 'absolute',
    top: '122px',
    width: '46%',
    left: '51%',
    height: '163px'
  },
  vetAttestationDetails: {
    position: 'absolute',
    top: '260px',
    width: '100%',
    height: '100px'
  },
  transportDetail: {
    position: 'absolute',
    top: '536px',
    width: '100%',
    height: '110px'
  },
  receivingLocation: {
    position: 'absolute',
    top: '645px',
    width: '100%',
    height: '160px'
  },
  withdrawlPeriodSection: {
    position: 'absolute',
    top: '612px',
    width: '100%',
    height: '85px'
  },
  fciDetail: {
    position: 'absolute',
    top: '435px',
    width: '100%',
    height: '200px'
  },
  extraSheetDetails: {
    position: 'absolute',
    top: '80px',
    width: '100%',
    height: '85px'
  },
  extraSheetTable: {
    left: '10px',
    width: '95%',
    border: '1px solid darkGrey'
  },
  addressToSendTo: {
    position: 'absolute',
    top: '780px',
    left: '28px',
    width: '90%'
  },
  fciPage: {
    flexDirection: 'column',
    fontSize: '11px',
    position: 'relative',
    top: 0
  },
  pageBackground: {
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: 'auto'
  },
  supplimentaryFormRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '535px',
    left: '30px',
    top: '203px'
  },
  supplimentaryFormColumn: {
    justifyContent: 'center',
    padding: '3px 0px 3px 5px',
    width: '25%',
    fontSize: '9px'
  },
  supplimentaryFormColumnTitle: {
    justifyContent: 'center',
    padding: '4px 0px 4px 5px',
    margin: '0px 0px 2px 0px',
    width: '100%',
    fontSize: '9px',
    backgroundColor: 'grey',
    color: '#FFF'
  },
  fciAnimalRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%',
    left: '30px',
    top: '170px'
  },

  fciAnimalColumn: {
    justifyContent: 'center',
    padding: '3px 0px 3px 5px',
    width: '25%',
    fontSize: '9px'
  }
};

const MovementDocumentPDF = ({
  attestationRecord,
  data
}) => {
  const { ready, t } = useTranslation();
  const { processingFlags = {} } = data || {};
  const { all: processingData = [] } = processingFlags || {};
  const { holding = '', vetAttestationExpiryDate = '', vetAttestationStatus = '', vetRCVS = '' } = attestationRecord || {};
  const expDate = vetAttestationExpiryDate ? moment(vetAttestationExpiryDate).format('MMYY') : vetAttestationExpiryDate;
  const { movementDocument } = data;
  const { transportDetail } = movementDocument;
  const departureDetail = movementDocument ? movementDocument.departureDetail : null;
  const transporterType = transportDetail && transportDetail.transporterType ? transportDetail.transporterType : null;
  const styles = StyleSheet.create(pdfProperties);
  const tagOutput = (batchNumber, individualNumber, from, to, addComma) => sprintf('%s %s%s%s', batchNumber, individualNumber, from !== to ? '-' + to : '', addComma ? ',' : '');
  const getTagList = (deviceList) => {
    let tagList = [];
    const results = [];
    let batchNumber = '';
    let individualNumber = '';
    let activeNo1 = 0;
    let activeNo2 = 0;

    for (const device of deviceList) {
      tagList.push(device.tagNumber);
    }
    tagList = helpers.sortTags(tagList);
    for (const tagItem of tagList) {
      const rec = tagItem.split(' ');

      if (rec.length > 1) {
        const tempIndividualNumber = Number(rec[1]);

        if (batchNumber !== rec[0] || (activeNo2 + 1) !== tempIndividualNumber) {
          if (batchNumber.length > 0) {
            results.push(tagOutput(batchNumber, individualNumber, activeNo1, activeNo2, true));
          }
          batchNumber = rec[0];
          individualNumber = rec[1];
          activeNo1 = tempIndividualNumber;
        }
        activeNo2 = tempIndividualNumber;
      } else {
        results.push(tagItem);
      }
    }
    results.push(tagOutput(batchNumber, individualNumber, activeNo1, activeNo2, false));
    return results;
  };

  const render = {
    pdfFooter: () => (
      <>
        <View style={{ position: 'absolute', top: '804px', left: '25px', fontSize: '8px', fontWeight: '600' }}>
          <Text>{t('label.movementRef') + ' : ' + data.documentRef + ' (' + data.species + ')'}</Text>
        </View>
        <View style={{ position: 'absolute', top: '804px', left: '525px', fontSize: '8px' }}>
          {/* <Text>Page {page} of {totalPages}</Text> */}
          <Text render={({ pageNumber, totalPages }) => (`Page ${pageNumber} of ${totalPages}`)} />
        </View>
      </>
    )
  };

  //--- PAGE 1 DATA & FIELDS ---//
  const getFciCheckbox = (fciData) => {
    if (!fciData || fciData.isAllAnimalsFciCompliant === null) {
      return null;
    }
    const style = fciData.isAllAnimalsFciCompliant
      ? { position: 'absolute', top: '446px', left: '270px', fontSize: '9px' }
      : { position: 'absolute', top: '501px', left: '270px', fontSize: '9px' };
    return (
      <View style={style}>
        <Text>X</Text>
      </View>
    );
  };

  const getTransportTypeCheckout = () => {
    let transportTypeStyle;
    switch (transporterType) {
      case constants.transporter.departureKeeper:
        transportTypeStyle = { position: 'absolute', top: '23px', left: '384px', fontSize: '9px' };
        break;

      case constants.transporter.destinationKeeper:
        transportTypeStyle = { position: 'absolute', top: '23px', left: '492px', fontSize: '9px' };
        break;

      case constants.transporter.haulier:
        transportTypeStyle = { position: 'absolute', top: '23px', left: '563px', fontSize: '9px' };
        break;

      default:
        return null;
    }
    return (
      <View style={transportTypeStyle}>
        <Text>X</Text>
      </View>
    );
  };

  const getWithdrawlPeriod = (withdrawlType) => {
    switch (withdrawlType) {
      case 'Withdrawal periods have been met':
        return <View style={{ position: 'absolute', top: '5px', left: '553px', fontSize: '9px' }}>
          <Text>{'X'}</Text>
        </View>;

      case 'Withdrawal periods are not relevant to this movement':
        return <View style={{ position: 'absolute', top: '21px', left: '553px', fontSize: '9px' }}>
          <Text>{'X'}</Text>
        </View>;

      case 'Withdrawal periods have not been met':
        return <View style={{ position: 'absolute', top: '54px', left: '35px', fontSize: '9px' }}>
          <Text>{withdrawlType}</Text>
        </View>;

      default:
        return null;
    }
  };

  const strikeFromOrTo = (flagData, flagId1, flagId2, flagId3, flagId4, flagId5, flagId6) => {
    const bothValues = flagData.filter((item) => ((item.id === flagId1)) && item.value === true);
    const toValues = flagData.filter((item) => ((item.id === flagId2) || (item.id === flagId3)) && item.value === true);
    const fromValues = flagData.filter((item) => ((item.id === flagId4) || (item.id === flagId5) || (item.id === flagId6)) && item.value === true);
    if (bothValues.length > 0) {
      return '';
    } else if (toValues.length > 0 && fromValues.length === 0) {
      return <View style={{ position: 'absolute', top: '6px', left: '23px', width: '6px', borderTop: '1px solid #1c1c1c' }}>
        <Text>{''}</Text>
      </View>;
    } else if (toValues.length === 0 && fromValues.length > 0) {
      return <View style={{ position: 'absolute', top: '6px', left: '5px', width: '15px', borderTop: '1px solid #1c1c1c' }}>
        <Text>{''}</Text>
      </View>;
    }
    return '';

  };

  const haulageCompanyIgnoreList = ['Departure keeper', 'Receiving keeper'];
  const getHaulierCompanyName = (haulierName) => {
    if (!haulierName) {
      return haulierName;
    }
    const matchedName = haulageCompanyIgnoreList.filter((name) => name === haulierName.substr(0, name.length));
    return matchedName.length > 0 ? '' : haulierName;
  };

  const setDepartTime = (inValue) => {
    if (!inValue) {
      return '';
    }
    const arr = inValue.split('+')[0].split('T')[1].split(':');
    if (arr[0] === '00' && arr[1] === '00') {
      return '';
    }
    return arr[0] + arr[1] ?? ' ';
  };

  const displayDataInCells = (value) => value.map((item, index) => {
    return <Text key={index} style={{ width: '15px', textAlign: 'center', marginRight: '1px' }}>{item}</Text>;
  });

  const displayDataInCellsWithVariation = (value) => value.map((item, index) => {
    return <Text key={index} style={{ width: '15.4px', textAlign: 'center', marginRight: '1px' }}>{item}</Text>;
  });

  let haulierCompanyName = '';
  let loadingTime = '';
  let timeOfDepature = '';
  let expectedDuration = '';
  let timeFirstAnimlLoaded = '';
  if (transportDetail && transportDetail.recordTransportInformation !== constants.option.recordTransportInformation.no) {
    haulierCompanyName = getHaulierCompanyName(transportDetail.transportHaulierName);
    if (departureDetail) {
      loadingTime = departureDetail.loadingDateTime ? moment(departureDetail.loadingDateTime).format('DD/MM/YY').split('/').join('') : '';
      timeFirstAnimlLoaded = departureDetail.loadingDateTime ? moment(departureDetail.loadingDateTime).format('HH:mm').split(':').join('') : '';
      timeOfDepature = setDepartTime(departureDetail.departureDateTime);
      const durationOfJourney = departureDetail.expectedDurationOfJourney;
      if (durationOfJourney) {
        const totalHours = (durationOfJourney.days * 24) + durationOfJourney.hours;
        const hours = sprintf('%s%s', totalHours < 10 ? '0' : '', totalHours);
        const minutes = sprintf('%s%s', durationOfJourney.minutes < 10 ? '0' : '', durationOfJourney.minutes);
        const findHours = hours + ':' + minutes;
        expectedDuration = findHours.split(':').join('');
      }
    }
  }

  let keeperTitle;
  let keeperFirstName;
  let keeperLastName;
  if (data.departAddress && data.departAddress.keeper) {
    keeperTitle = data.departAddress.keeper.title ? data.departAddress.keeper.title + ' ' : '';
    keeperFirstName = data.departAddress.keeper.firstName ? data.departAddress.keeper.firstName + ' ' : '';
    keeperLastName = data.departAddress.keeper.lastName ? data.departAddress.keeper.lastName : '';
  }
  const toHolding = data.toHolding ? data.toHolding.split('/').join('') : ' ';
  const keeperName = keeperTitle + keeperFirstName + keeperLastName;
  const transporterAuthNumber = transportDetail && transportDetail.transporterAuthNumber ? transportDetail.transporterAuthNumber.substr(0, 9) : ' ';

  const driverFirstName = transportDetail && transportDetail.transporter && transportDetail.transporter.firstName ? transportDetail.transporter.firstName.slice(0, 1) + '. ' : ' ';
  const driverLastName = transportDetail && transportDetail.transporter && transportDetail.transporter.lastName ? transportDetail.transporter.lastName : ' ';
  const driverName = driverFirstName + driverLastName;
  const driverTelephone = transportDetail && transportDetail.transporter && transportDetail.transporter.telephoneNumber ? transportDetail.transporter.telephoneNumber : ' ';

  const ownerFirstName = departureDetail && departureDetail.departureOwner && departureDetail.departureOwner.firstName ? departureDetail.departureOwner.firstName.slice(0, 1) + '. ' : ' ';
  const ownerLastName = departureDetail && departureDetail.departureOwner && departureDetail.departureOwner.lastName ? departureDetail.departureOwner.lastName : ' ';
  const nameOfOwner = ownerFirstName + ownerLastName;
  const printName = data.departAddress && data.departAddress.firstName && data.departAddress.lastName ? data.departAddress.firstName + ' ' + data.departAddress.lastName : ' ';

  //--- PAGE 2 DATA & FIELDS ---//
  const nonCompliantReason = data.movementDocument.fciDetail.nonCompliantReason ? data.movementDocument.fciDetail.nonCompliantReason : ' ';
  const withdrawalPeriodObservedText = data.movementDocument.fciDetail.withdrawalPeriodObservedText ? data.movementDocument.fciDetail.withdrawalPeriodObservedText : ' ';
  const holdingRestrictions = data.movementDocument.fciDetail.holdingRestrictions ? data.movementDocument.fciDetail.holdingRestrictions : ' ';

  //--- PAGE 3 - SUPPLIMENTARY FORM FIELDS ---//
  const loadingDate = moment(data.movementDate).format('DD/MM/YY').split('/').join('');
  const depatureDate = moment(data.movementDate).format('DD/MM/YY').split('/').join('');

  //--- PAGE 1 ---//
  const PDF_Page1 = () => (
    <Page size="A4" style={styles.page}>
      <Image
        src={BackgroundPage1}
        style={styles.image}
      />

      {/* Section 1 - Left side Section */}
      <View style={styles.departureLocation}>
        <View style={{ position: 'absolute', top: '74px', left: '155px' }}>
          <Text style={{ letterSpacing: '10px' }}>{data.fromHolding.split('/').join('')}</Text>
        </View>
        {data.departAddress &&
          <>
            <View style={{ position: 'absolute', top: '111px', left: '45px', fontSize: '8px' }}>
              <Text>{keeperName ? keeperName : ''}</Text>
              <Text style={{}}>{data.departAddress?.propertyName ?? ''}</Text>
              <Text style={{}}>{data.departAddress?.address1 ?? ''}</Text>
              <Text style={{}}>{data.departAddress?.address2 ?? ''}</Text>
              <Text style={{}}>{data.departAddress?.town ?? ''}</Text>
              <Text style={{}}>
                {data.departAddress?.county ?? ''}
                {data.departAddress?.postCode ? ' - ' + data.departAddress?.postCode : ''}
              </Text>
            </View>
          </>
        }
        {data.destAddress &&
          <>
            <View style={{ position: 'absolute', top: '190px', left: '45px', fontSize: '8px' }}>
              <Text style={{}}>{data.destAddress?.propertyName ?? ''}</Text>
              <Text style={{}}>{data.destAddress?.address1 ?? ''}</Text>
              <Text style={{}}>{data.destAddress?.address2 ?? ''}</Text>
              <Text style={{}}>{data.destAddress?.town ?? ''}</Text>
              <Text style={{}}>
                {data.destAddress?.county ?? ''}
                {data.destAddress?.postCode ? ' - ' + data.destAddress?.postCode : ''}
              </Text>
            </View>
          </>
        }
      </View>

      { data.species === 'Sheep' && vetAttestationStatus !== '' &&
        <View style={styles.vetAttestationDetails}>
          <View style={{ position: 'absolute', top: '78px', left: '187px' }}>
            <Text style={{ letterSpacing: '10px' }}>{vetRCVS}</Text>
          </View>
          <View style={{ position: 'absolute', top: '100px', left: '155px' }}>
            <Text style={{ letterSpacing: '10px' }}>{holding.split('/').join('')}</Text>
          </View>
          <View style={{ position: 'absolute', top: '122px', left: '235px' }}>
            <Text style={{ letterSpacing: '10px' }}>{expDate}</Text>
          </View>
        </View>
      }

      {getFciCheckbox(data.movementDocument?.fciDetail)}

      {/* Section 1 - Right side Section */}
      { processingData.length > 0 &&
        <View style={styles.processingData}>

          <View style={{ position: 'absolute', top: '2px', left: '179px' }}>
            <Text style={{ fontFamily: 'Helvetica-Bold' }}>{data.animalTotal}</Text>
          </View>
          <View style={{ position: 'absolute', top: '35px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 21) ? 'X' : '' }</Text>
          </View>
          <View style={{ position: 'absolute', top: '54px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 20) ? 'X' : '' }</Text>
          </View>
          <View style={{ position: 'absolute', top: '73px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 8) ? 'X' : '' }</Text>
          </View>
          <View style={{ position: 'absolute', top: '92px', left: '258px', fontSize: '9px' }}>
            <Text>{'X'}</Text>
          </View>
          <View style={{ position: 'absolute', top: '111px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 22) ? 'X' : '' }</Text>
          </View>
          <View style={{ position: 'absolute', top: '130px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 28, 30, 36, 37, 40, 43) ? 'X' : '' }</Text>
          </View>
          <View style={{ position: 'absolute', top: '125px', left: '30px', width: '40px', height: '12px' }}>
            {strikeFromOrTo(processingData, 30, 28, 37, 36, 40, 43)}
          </View>

          <View style={{ position: 'absolute', top: '149px', left: '258px', fontSize: '9px' }}>
            <Text>{helpers.getProcessingCheckStatus(processingData, 6) ? 'X' : '' }</Text>
          </View>
        </View>
      }

      <View style={styles.departDetailSection}>
        <View style={{ position: 'absolute', top: '5px', left: '181px' }}>
          <Text style={{ letterSpacing: '10.2px' }}>{depatureDate}</Text>
        </View>
        <View style={{ position: 'absolute', top: '27px', left: '181px' }}>
          <Text style={{ letterSpacing: '10.2px' }}>{loadingTime}</Text>
        </View>
        <View style={{ position: 'absolute', top: '48.5px', left: '214px' }}>
          <Text style={{ letterSpacing: '10.2px' }}>{timeFirstAnimlLoaded}</Text>
        </View>
        <View style={{ position: 'absolute', top: '70px', left: '214px' }}>
          <Text style={{ letterSpacing: '10.2px' }}>{timeOfDepature}</Text>
        </View>
        <View style={{ position: 'absolute', top: '92px', left: '214px' }}>
          <Text style={{ letterSpacing: '10.2px' }}>{expectedDuration}</Text>
        </View>
        <View style={{ position: 'absolute', top: '151px', left: '64px', width: '205px', height: '15px' }}>
          <Text>{printName}</Text>
        </View>
        {/* <View style={{ position: 'absolute', top: '173px', left: '86px' }}>
          <Text style={{ letterSpacing: '10px' }}>{data.departAddress ? data.departAddress.telephoneNumber : ''}</Text>
        </View> */}
        <View style={{ position: 'absolute', top: '172.5px', left: '82px', flexDirection: 'row', alignItems: 'center' }}>
          {/* <Text style={{ letterSpacing: '10px' }}>{data.departAddress ? data.departAddress.telephoneNumber : ''}</Text> */}
          {data.departAddress && data.departAddress.telephoneNumber ? displayDataInCells((data.departAddress.telephoneNumber).split('')) : ''}
        </View>
        <View style={{ position: 'absolute', top: '231px', left: '18px' }}>
          <Text>{nameOfOwner}</Text>
        </View>
      </View>

      {/* Section 2 - Transport Details */}
      <View style={styles.transportDetail}>
        {getTransportTypeCheckout()}
        {data.transportVehicleRegistrationNo &&
          <View style={{ position: 'absolute', top: '42px', left: '136px', height: '15px', flexDirection: 'row', alignItems: 'center' }}>
            {displayDataInCells((data.transportVehicleRegistrationNo).split(''))}
          </View>
        }
        <View style={{ position: 'absolute', top: '65px', left: '115px', width: '175px', height: '15px' }}>
          <Text>{haulierCompanyName}</Text>
        </View>
        <View style={{ position: 'absolute', top: '86px', left: '152px', height: '15px', flexDirection: 'row', alignItems: 'center' }}>
          {displayDataInCells((transporterAuthNumber).split(''))}
        </View>
        <View style={{ position: 'absolute', top: '66px', left: '363px', width: '205px', height: '15px' }}>
          <Text>{driverName}</Text>
        </View>
        <View style={{ position: 'absolute', top: '86px', left: '380px', height: '15px', flexDirection: 'row', alignItems: 'center' }}>
          {displayDataInCells((driverTelephone).split(''))}
        </View>

      </View>

      {/* Section 3 - Receiving Location */}
      <View style={styles.receivingLocation}>
        <View style={{ position: 'absolute', top: '26px', left: '150px', height: '15px', flexDirection: 'row', alignItems: 'center' }}>
          {displayDataInCellsWithVariation((toHolding).split(''))}
        </View>
        <View style={{ position: 'absolute', top: '28.5px', left: '479px' }}>
          <Text style={{ letterSpacing: '10px' }}>{loadingDate}</Text>
        </View>
        <View style={{ position: 'absolute', top: '70px', left: '222px', width: '78px', textAlign: 'right' }}>
          <Text style={{ letterSpacing: '10px' }}>{data.animalTotal}</Text>
        </View>
      </View>
      {render.pdfFooter()}
    </Page>
  );

  //--- PAGE 2 ---//
  const PDF_Page2 = () => (
    <Page size="A4" style={styles.page}>
      <Image
        src={BackgroundPage2}
        style={styles.image}
      />
      {fciDetails.nonCompliantDevices && fciDetails.nonCompliantDevices.length > 0 &&
        <View style={{ position: 'absolute', top: '525px', left: '35px', fontSize: '9px', width: '520px', fontWeight: '900' }}>
          <Text style={{ fontFamily: 'Helvetica-Bold' }}>{'Please see Additional FCI Animals list'}</Text>
        </View>
      }
      <View style={{ position: 'absolute', top: '580px', left: '35px', fontSize: '9px', width: '520px' }}>
        <Text>{nonCompliantReason}</Text>
      </View>
      <View style={styles.withdrawlPeriodSection}>
        {getWithdrawlPeriod(withdrawalPeriodObservedText)}
      </View>
      <View style={{ position: 'absolute', top: '715px', left: '35px', fontSize: '9px', width: '520px' }}>
        <Text>{holdingRestrictions}</Text>
      </View>
      {render.pdfFooter()}
    </Page>
  );

  // -----PAGE 3 - SUPPLIMENTARY FORM ----//
  const splittedList = [];
  const allIndividualTagList = [];
  const allBatchTagList = [];
  let allTagList = [];

  const getAllTagAnimals = () => {
    //--- Get batch list ---//
    if (data.batches?.length > 0) {
      data.batches.forEach((item, index) => {
        const newBatch = index === (data.batches?.length - 1) ? item.batchNumber + ' X ' + item.animalTotal : item.batchNumber + ' X ' + item.animalTotal + ',';
        allBatchTagList.push(newBatch);
      });
    }
    allBatchTagList.unshift('Animals in Batches = ' + data.totalBatchAnimals);

    //--- Get device list ---//
    if (data.devices?.length > 0) {
      const devicelist = getTagList(data.devices);
      allIndividualTagList.push(...devicelist);
    }
    allIndividualTagList.unshift('Individual Animals = ' + data.totalIndividualAnimals);

    //--- Merge both Batch and Device ---//
    allTagList = [...allBatchTagList, ...allIndividualTagList];

    //--- Split into multiple array to generate dynamic pages ---//
    while (allTagList.length) {
      splittedList.push(allTagList.splice(0, 111));
    }
  };

  getAllTagAnimals();

  const AnimalData = ({ children }) => (
    <>
      <Image src={children === splittedList.length - 1 ? SuplimentaryFormLast : SuplimentaryForm } style={styles.pageBackground} />
      {children === splittedList.length - 1 &&
        <View style={{ position: 'absolute', top: '695px', left: '132px', fontSize: '10px' }}>
          <Text>{data.animalTotal}</Text>
        </View>
      }

      <View style={styles.supplimentaryFormRow}>
        {splittedList[children].map((label, index) => (
          <View key={index} style={(label.startsWith('Individual') || label.startsWith('Animals')) ? styles.supplimentaryFormColumnTitle : styles.supplimentaryFormColumn}>
            <Text>{label}</Text>
          </View>
        ))}
      </View>
    </>
  );

  const SupplementaryFormPage = splittedList.map((item, index) => {
    return <Page key={index} size="A4" style={styles.fciPage}>
      <AnimalData>{index}</AnimalData>
      <View style={{ position: 'absolute', top: '125px', left: '83px' }}>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: '10px' }}>{data.species}</Text>
      </View>
      <View style={{ position: 'absolute', top: '159px', left: '34px' }}>
        <Text style={{ letterSpacing: '10px' }}>{data.fromHolding.split('/').join('')}</Text>
      </View>
      <View style={{ position: 'absolute', top: '159px', left: '187px', flexDirection: 'row', alignItems: 'center' }}>
        {displayDataInCellsWithVariation((toHolding).split(''))}
      </View>
      <View style={{ position: 'absolute', top: '159px', left: '352px' }}>
        <Text style={{ letterSpacing: '10px' }}>{loadingDate}</Text>
      </View>
      {render.pdfFooter()}
    </Page>;
  });

  // ----- PAGE 4 - ADDITIONAL FCI FORM ----//
  const fciDetails = data.movementDocument ? data.movementDocument.fciDetail : null;
  const fciAnimalList = [];
  const fciSplittedList = [];
  if (fciDetails && fciDetails.nonCompliantDevices && fciDetails.nonCompliantDevices.length > 0) {
    fciDetails.nonCompliantDevices.forEach((item) => {
      fciAnimalList.push(item.tagNumber);
    });
  }

  while (fciAnimalList.length) {
    fciSplittedList.push(fciAnimalList.splice(0, 148));
  }

  const FciAnimalData = ({ children }) => (
    <>
      <Image src={children === fciSplittedList.length - 1 ? FciAnimalFormLast : FciAnimalForm } style={styles.pageBackground} />
      {children === fciSplittedList.length - 1 &&
        <View style={{ position: 'absolute', top: '771px', left: '132px', fontSize: '10px' }}>
          <Text>{fciDetails.nonCompliantDevices.length}</Text>
        </View>
      }
      <View style={styles.fciAnimalRow}>
        {fciSplittedList[children].map((label, index) => (
          <View key={index} style={styles.fciAnimalColumn}>
            <Text>{label}</Text>
          </View>
        ))}
      </View>
    </>
  );

  const AdditionalFciPage = fciSplittedList.map((item, index) => {
    return <Page key={index} size="A4" style={styles.fciPage}>
      <FciAnimalData>{index}</FciAnimalData>
      {render.pdfFooter()}
    </Page>;
  });

  return (
    <>
      {ready &&
      <Document>
        {PDF_Page1()}
        {PDF_Page2()}
        {SupplementaryFormPage}
        {AdditionalFciPage}
      </Document>
      }
    </>
  );
};

MovementDocumentPDF.propTypes = {
  data: PropTypes.object.isRequired
};

export default MovementDocumentPDF;
