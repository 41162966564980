import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from 'components/animalSummary/animalSummary.module.scss';
import constants from 'services/constants';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import { tableColumns, initialSorting } from 'services/tableColumns';
import Table from 'components/table/table';

const AnimalSummary = ({
  batches,
  batchesRejected,
  extraData,
  hideAnimalDetails,
  individualAnimals,
  individualAnimalsRejected,
  isReviewedAndRejected,
  noBottomBorder,
  printing,
  requestType,
  setBatches,
  setBatchesRejected,
  setIndividualAnimals,
  setIndividualAnimalsRejected,
  setModal,
  totalAnimals
}) => {
  const { ready, t } = useTranslation();

  const species = storeService.session.get.species();
  const speciesId = species ? species.id : '';
  const recentDetailsLabel = extraData ? 'recentDetails.' + extraData.gender : '';

  const [totalIndividualAnimals, setTotalIndividualAnimals] = React.useState(0);
  const [totalIndividualAnimalsRejected, setTotalIndividualAnimalsRejected] = React.useState(0);
  const [totalBatchAnimals, setTotalBatchAnimals] = React.useState(0);
  const [totalBatchAnimalsRejected, setTotalBatchAnimalsRejected] = React.useState(0);
  const [paginationParams, setPaginationParams] = React.useState({
    batch: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    batchRejected: {
      ...constants.pagination.defaultParams,
      [constants.pagination.bottom]: {
        ...constants.pagination.defaultParams[constants.pagination.bottom],
        totalLabel: 'label.totalBatches'
      }
    },
    individual: constants.pagination.defaultParams,
    individualRejected: constants.pagination.defaultParams
  });

  const componentClassNames = [
    classes.animalCount,
    noBottomBorder ? classes.noBottomBorder : ''
  ].join(' ').trim();

  const content = {
    [constants.option.requestType.animalsAdded]: {
      totalIndividualAnimals: 'recentDetails.totalAnimalsAdded'
    },
    [constants.option.requestType.birth]: {
      totalAnimals: 'recentDetails.totalAnimalsBorn',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.correctTransfer]: {
      totalAnimals: 'recentDetails.totalAnimalsMoved',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.death]: {
      totalAnimals: 'recentDetails.totalAnimalsDied',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.movement]: {
      totalAnimals: 'recentDetails.totalAnimalsMoved',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals',
      totalAnimalsRejected: 'recentDetails.totalAnimalsRejected',
      totalBatchAnimalsRejected: 'recentDetails.totalBatchAnimalsRejected',
      totalIndividualAnimalsRejected: 'recentDetails.totalIndividualAnimalsRejected'
    },
    [constants.option.requestType.movementHandshake]: {
      totalAnimals: 'recentDetails.totalAnimalsAccepted',
      totalBatchAnimals: 'recentDetails.totalBatchAnimalsAccepted',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimalsAccepted',
      totalAnimalsRejected: 'recentDetails.totalAnimalsRejected',
      totalBatchAnimalsRejected: 'recentDetails.totalBatchAnimalsRejected',
      totalIndividualAnimalsRejected: 'recentDetails.totalIndividualAnimalsRejected'
    },
    [constants.option.requestType.paperMovementAdd]: {
      totalAnimals: 'recentDetails.totalAnimalsMoved',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.paperMovementEdit]: {
      totalAnimals: 'recentDetails.totalAnimalsMoved',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.tagApplication]: {
      totalAnimals: 'recentDetails.totalAnimalsTaggedRegistered',
      totalBatchAnimals: 'recentDetails.totalBatchAnimals',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimals'
    },
    [constants.option.requestType.tagReplacement]: {
      totalAnimals: 'recentDetails.totalAnimalsTagged',
      totalBatchAnimals: 'recentDetails.totalBatchAnimalsReplaced',
      totalIndividualAnimals: 'recentDetails.totalIndividualAnimalsReplaced'
    }
  };

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        [constants.pagination.bottom]: {
          ...prevState.batch[constants.pagination.bottom],
          total: batches?.length
        }
      }
    }));

    setTotalBatchAnimals(batches?.reduce((total, item) => total + parseInt(item.animalTotal), 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batches]);

  useEffect(() => {
    setPaginationParams((prevState) => ({
      ...prevState,
      batchRejected: {
        ...prevState.batchRejected,
        [constants.pagination.bottom]: {
          ...prevState.batchRejected[constants.pagination.bottom],
          total: batchesRejected?.length
        }
      }
    }));

    setTotalBatchAnimalsRejected(batchesRejected?.reduce((total, item) => total + parseInt(item.animalTotal), 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchesRejected]);

  useEffect(() => {
    const total = individualAnimals?.length;

    setPaginationParams((prevState) => ({
      ...prevState,
      individual: {
        ...prevState.individual,
        [constants.pagination.bottom]: {
          ...prevState.individual[constants.pagination.bottom],
          total
        }
      }
    }));

    setTotalIndividualAnimals(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualAnimals]);

  useEffect(() => {
    const total = individualAnimalsRejected?.length;

    setPaginationParams((prevState) => ({
      ...prevState,
      individualRejected: {
        ...prevState.individualRejected,
        [constants.pagination.bottom]: {
          ...prevState.individualRejected[constants.pagination.bottom],
          total
        }
      }
    }));

    setTotalIndividualAnimalsRejected(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualAnimalsRejected]);

  return (
    <>
      {ready &&
        <>
          {((totalBatchAnimals > 0 || totalIndividualAnimals > 0) && !isReviewedAndRejected) &&
            <div className={componentClassNames}>
              {!helpers.option.requestType.isAnimalsAdded(requestType) &&
                <>
                  <div className={classes.row}>
                    <div className={classes.label}>{t(isReviewedAndRejected ? content[constants.option.requestType.movementHandshake]?.totalAnimalsRejected : content[requestType]?.totalAnimals)}</div>
                    <div className={classes.count}>{totalBatchAnimals + totalIndividualAnimals}</div>
                  </div>

                  {totalBatchAnimals > 0 && !hideAnimalDetails &&
                    <Table
                      columns={helpers.option.requestType.isBirth(requestType)
                        ? tableColumns.animalSummary.birth
                        : (helpers.option.requestType.isTagReplacement(requestType)
                          ? tableColumns.animalSummary.batchTagReplacement({ speciesId })
                          : tableColumns.animalSummary.batch({ speciesId })
                        )
                      }
                      data={batches}
                      dataProvided={true}
                      hidePagination={printing}
                      initialSortBy={helpers.option.requestType.isBirth(requestType)
                        ? initialSorting.animalSummary.individual.accessor
                        : initialSorting.animalSummary.batch.accessor
                      }
                      initialSortDirection={helpers.option.requestType.isBirth(requestType)
                        ? initialSorting.animalSummary.individual.direction
                        : initialSorting.animalSummary.batch.direction
                      }
                      paginationParams={paginationParams.batch}
                      setData={setBatches}
                      setModal={setModal}
                      title={{
                        title: helpers.text.pluralCheck(batches?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batches'),
                        data: [
                          batches?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                        ]
                      }}
                    />
                  }
                </>
              }

              {!hideAnimalDetails &&
                <>
                  {totalIndividualAnimals > 0 &&
                    <Table
                      columns={helpers.option.requestType.isAnimalsAdded(requestType)
                        ? tableColumns.animalSummary.individualActivityDetailsAnimalsAdded
                        : helpers.option.requestType.isBirth(requestType)
                          ? tableColumns.animalSummary.birth
                          : (helpers.option.requestType.isTagReplacement(requestType)
                            ? tableColumns.animalSummary.individualTagReplacement
                            : tableColumns.animalSummary.individualActivityDetails
                          )
                      }
                      data={individualAnimals}
                      dataProvided={true}
                      hidePagination={printing}
                      initialSortBy={helpers.option.requestType.isTagReplacement(requestType)
                        ? initialSorting.animalSummary.individualTagReplacement.accessor
                        : initialSorting.animalSummary.individual.accessor
                      }
                      initialSortDirection={helpers.option.requestType.isTagReplacement(requestType)
                        ? initialSorting.animalSummary.individualTagReplacement.direction
                        : initialSorting.animalSummary.individual.direction
                      }
                      paginationParams={paginationParams.individual}
                      setData={setIndividualAnimals}
                      setModal={setModal}
                      title={{
                        title: 'table.title.individualAnimals',
                        data: [
                          individualAnimals?.length
                        ]
                      }}
                    />
                  }
                </>
              }
            </div>
          }

          {(helpers.option.requestType.isMovementHandshake(requestType) || isReviewedAndRejected) &&
            (totalBatchAnimalsRejected > 0 || (totalIndividualAnimalsRejected + totalBatchAnimalsRejected) > 0) &&
            <div className={classes.animalCount}>
              <div className={classes.row}>
                <div className={classes.label}>{t(content[requestType].totalAnimalsRejected)}</div>
                <div className={classes.count}>{totalIndividualAnimalsRejected + totalBatchAnimalsRejected}</div>
              </div>

              {totalBatchAnimalsRejected > 0 &&
                <Table
                  columns={helpers.option.requestType.isBirth(requestType)
                    ? tableColumns.animalSummary.birth
                    : (helpers.option.requestType.isTagReplacement(requestType)
                      ? tableColumns.animalSummary.batchTagReplacement({ speciesId })
                      : tableColumns.animalSummary.batch({ speciesId })
                    )
                  }
                  data={batchesRejected}
                  dataProvided={true}
                  hidePagination={printing}
                  initialSortBy={helpers.option.requestType.isBirth(requestType)
                    ? initialSorting.animalSummary.individual.accessor
                    : initialSorting.animalSummary.batch.accessor
                  }
                  initialSortDirection={helpers.option.requestType.isBirth(requestType)
                    ? initialSorting.animalSummary.individual.direction
                    : initialSorting.animalSummary.batch.direction
                  }
                  paginationParams={paginationParams.batchRejected}
                  setData={setBatchesRejected}
                  setModal={setModal}
                  title={{
                    title: helpers.text.pluralCheck(batchesRejected?.reduce((total, item) => total + parseInt(item.animalTotal), 0), 'table.title.batchesRejected'),
                    data: [
                      batchesRejected?.reduce((total, item) => total + parseInt(item.animalTotal), 0)
                    ]
                  }}
                />
              }

              {totalIndividualAnimalsRejected > 0 &&
                <Table
                  columns={helpers.option.requestType.isBirth(requestType)
                    ? tableColumns.animalSummary.birth
                    : (helpers.option.requestType.isTagReplacement(requestType)
                      ? tableColumns.animalSummary.individualTagReplacement
                      : tableColumns.animalSummary.individualActivityDetails
                    )
                  }
                  data={individualAnimalsRejected}
                  dataProvided={true}
                  hidePagination={printing}
                  initialSortBy={helpers.option.requestType.isTagReplacement(requestType)
                    ? initialSorting.animalSummary.individualTagReplacement.accessor
                    : initialSorting.animalSummary.individual.accessor
                  }
                  initialSortDirection={helpers.option.requestType.isTagReplacement(requestType)
                    ? initialSorting.animalSummary.individualTagReplacement.direction
                    : initialSorting.animalSummary.individual.direction
                  }
                  paginationParams={paginationParams.individualRejected}
                  setData={setIndividualAnimalsRejected}
                  setModal={setModal}
                  title={{
                    title: 'table.title.individualAnimalsRejected',
                    data: [
                      individualAnimalsRejected?.length
                    ]
                  }}
                />
              }
            </div>
          }

          {helpers.option.requestType.isTagApplication(requestType) && extraData &&
            (extraData.dob || extraData.breed || extraData.gender || extraData.genotype) &&
            <div className={classes.animalCount}>
              {extraData.dob &&
                <div className={classes.row}>
                  <div className={classes.label}>{extraData.dob.toString().length === 4 ? t('label.yearOfBirth') : t('recentDetails.dateOfBirth')}</div>
                  <div className={classes.count}>{extraData.dob}</div>
                </div>
              }
              {extraData.breed &&
                <div className={classes.row}>
                  <div className={classes.label}>{t('recentDetails.breed')}</div>
                  <div className={classes.count}>{extraData.breed}</div>
                </div>
              }
              {extraData.gender &&
                <div className={classes.row}>
                  <div className={classes.label}>{t('recentDetails.gender')}</div>
                  <div className={classes.count}>{t(recentDetailsLabel)}</div>
                </div>
              }
              {extraData.genotype &&
                <div className={classes.row}>
                  <div className={classes.label}>{t('recentDetails.genotype')}</div>
                  <div className={classes.count}>{extraData?.genotype ? he.decode(extraData.genotype) : ''}</div>
                </div>
              }
            </div>
          }

          {totalBatchAnimals === 0 && totalIndividualAnimals === 0 && totalAnimals > 0 &&
            <div className={componentClassNames}>
              <div className={classes.row}>
                <div className={classes.label}>{t(content[requestType]?.totalAnimals)}</div>
                <div className={classes.count}>{totalAnimals}</div>
              </div>
            </div>
          }
        </>
      }
    </>
  );
};

AnimalSummary.propTypes = {
  batches: PropTypes.array,
  batchesRejected: PropTypes.array,
  extraData: PropTypes.object,
  hideAnimalDetails: PropTypes.bool,
  individualAnimals: PropTypes.array,
  individualAnimalsRejected: PropTypes.array,
  noBottomBorder: PropTypes.bool,
  printing: PropTypes.bool,
  requestType: PropTypes.number,
  setBatches: PropTypes.func,
  setBatchesRejected: PropTypes.func,
  setIndividualAnimals: PropTypes.func,
  setIndividualAnimalsRejected: PropTypes.func,
  setModal: PropTypes.func.isRequired
};

export default AnimalSummary;
