import bff from 'services/bff';
import axios from 'axios';

const vetModuleHelper = {
  fetchVetDetails: (cphNo, isOnlyValidattestation) => {
    const source = axios.CancelToken.source();
    const api = '/getVetAttestationsDetails';
    return bff
      .get(api, {
        cancelToken: source.token,
        params: {
          cphNo,
          showOnlyValidAttestation: isOnlyValidattestation
        }
      });
  },
  fetchAbattoirVetAttestationList: (species, deviceIdentifiers) => {
    const source = axios.CancelToken.source();
    const api = '/speciesIdAndDeviceIdentifiers';
    return bff
      .get(api, {
        cancelToken: source.token,
        params: {
          species,
          deviceIdentifiers
        }
      });
  },
  fetchVetDetailsForDigitalForm: (dfCphNo, dfMoveDate) => {
    const dfSource = axios.CancelToken.source();
    const dfApi = '/getVetAttestationsDetails';
    return bff
      .get(dfApi, {
        cancelToken: dfSource.token,
        params: {
          cphNo: dfCphNo,
          moveDate: dfMoveDate
        }
      });
  }
};

export default vetModuleHelper;
