import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import bff, { bffStatus } from 'services/bff';
import classes from 'frontEnd/movementsGatherings/movementsGatherings.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import navigation from 'services/navigation';
import permissions from 'services/permissions';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import userRoleType from 'services/userRoleType';
import AddressSummary from 'components/summary/addressSummary/addressSummary';
import AnimalsBatches from 'frontEnd/movementsGatherings/animalsBatches/animalsBatches';
import AnimalsIndividuals from 'frontEnd/movementsGatherings/animalsIndividuals/animalsIndividuals';
import Button from 'components/base/button/button';
import Confirmation from 'components/base/confirmation/confirmation';
import DropdownHoldings from 'components/dropdownHoldings/dropdownHoldings';
import Exemptions from 'frontEnd/movementsGatherings/exemptions/exemptions';
import FoodChainInformation from 'frontEnd/movementsGatherings/foodChainInformation/foodChainInformation';
import InputDate from 'components/base/inputDate/inputDate';
import MovementType from 'frontEnd/movementsGatherings/movementType/movementType';
import OtherHoldingSelection from 'components/otherHoldingSelection/otherHoldingSelection';
import RadioButtons from 'components/base/radioButtons/radioButtons';
import TransportDetails from 'frontEnd/movementsGatherings/transportDetails/transportDetails';
import { cloneDeep, isEqual } from 'lodash';
import configHelper from 'services/configHelper';

const MovementsGatherings = ({
  location,
  setModal,
  setPermission
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const role = storeService.session.get.permissions();
  const sessionMovement = storeService.session.get.movement();
  const sessionDataChanged = storeService.session.get.dataChanged();

  const isAbattoir = permissions.isAbattoir();
  const isMarket = permissions.isMarket();
  const isGathering = permissions.isGathering();
  const species = storeService.session.get.species();
  const holding = storeService.session.get.holding();
  const holdings = storeService.session.get.holdings();
  const numberOfHoldings = holdings ? Object.keys(holdings).length : 0;
  const departureDaysAhead = configHelper.get.offMoveMaxDays(species.id);

  const ERR_TEMPLATE = { type: '', value: '' };

  const [inBusinessMovement, setInBusinessMovement] = React.useState(false);

  const [movementType, setMovementType] = React.useState(sessionMovement?.movementType ?? constants.option.movement.on);
  const [marketType, setMarketType] = React.useState(constants.option.market.green);
  const [validateErrors, setValidateErrors] = React.useState([]);

  const [options, setOptions] = React.useState([]);
  const [batchTotal, setBatchTotal] = React.useState(0);
  const [animalDetails, setAnimalDetails] = React.useState([]);

  const [animalDetailsValidation, setAnimalDetailsValidation] = React.useState(0);
  const sessionRequestId = storeService.session.get.confirmRequestId();
  const [requestId, setRequestId] = React.useState(sessionRequestId ?? null);

  const [departData, setDepartData] = React.useState({
    CPH: helpers.option.movement.isOff(movementType) ? holding.value : '',
    date: '',
    manualCPH: ''
  });
  const [arriveData, setArriveData] = React.useState({
    CPH: helpers.option.movement.isOn(movementType) ? holding.value : '',
    date: '',
    manualCPH: ''
  });

  const [transportDetails, setTransportDetails] = React.useState({
    transportedBy: '',
    vehicleRegistrationNumber: '',
    transporterName: '',
    transportAuthorisationNumber: ''
  });

  const [departureDateError, setDepartureDateError] = React.useState(ERR_TEMPLATE);
  const [arrivalDateError, setArrivalDateError] = React.useState(ERR_TEMPLATE);
  const [batchList, setBatchList] = React.useState([]);
  const [transportDetailsError, setTransportDetailsError] = React.useState(ERR_TEMPLATE);
  const [haulierNameError, setHaulierNameError] = React.useState(ERR_TEMPLATE);
  const [transportAuthNumberError, setTransportAuthNumberError] = React.useState(ERR_TEMPLATE);
  const [vehicleRegNoError, setVehicleRegNoError] = React.useState('');
  const [fciData, setFciData] = React.useState({});
  const [metFCIWithdrawalPeriods, setMetFCIWithdrawalPeriods] = React.useState([]);
  const [resetForm, setResetForm] = React.useState(false);

  const [submitDisabled, setSubmitDisabled] = React.useState(false);

  const sessionStatus = storeService.session.get.confirmPollingStatus();
  const [pollingStatus, setPollingStatus] = React.useState(sessionStatus ?? null);

  const sessionMovementReference = storeService.session.get.confirmMovementReference();
  const [movementRef, setMovementRef] = React.useState(sessionMovementReference ?? null);
  const [submitted, setSubmitted] = React.useState(Boolean(sessionMovementReference) || Boolean(requestId));
  const [validationError, setValidationError] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  const [dataChanged, setDataChanged] = React.useState(sessionDataChanged ?? false);
  const [isAmend, setIsAmend] = React.useState(sessionMovement?.amend ?? false);
  const [amendChangedData, setAmendChangedData] = React.useState(false);
  // const [choiceDisabled, setChoiceDisabled] = React.useState(false);

  const [departAddress, setDepartAddress] = React.useState({});
  const [arriveAddress, setArriveAddress] = React.useState({});

  const dataHasChanged = () => {
    setDataChanged(true);
    storeService.session.set.dataChanged(true);
  };

  const resetAddresses = () => {
    setDepartAddress({});
    setArriveAddress({});
  };

  const setDataFromSessionStorage = (session) => {
    if (session.movementType) {
      setMovementType(session.movementType);
    } else {
      storeService.session.set.movementType(constants.option.movement.on);
    }

    if (session.movementInBusiness) {
      setInBusinessMovement(true);
    }

    if (session.departureDate) {
      setDepartData((prevState) => ({
        ...prevState,
        date: session.departureDate
      }));
      validate.date(constants.flags.departure, session.departureDate);
    }

    if (session.arrivalDate) {
      setArriveData((prevState) => ({
        ...prevState,
        date: session.arrivalDate
      }));
      validate.date(constants.flags.destination, session.arrivalDate);
    }

    if (session.otherHolding) {
      const type = session.movementType ?? storeService.session.get.movementType();

      if (helpers.option.movement.isOff(type)) {
        setDepartData((prevState) => ({
          ...prevState,
          CPH: holding.value
        }));
        setArriveData((prevState) => ({
          ...prevState,
          CPH: session.otherHolding.value
        }));
      } else if (helpers.option.movement.isOn(type)) {
        setDepartData((prevState) => ({
          ...prevState,
          CPH: session.otherHolding.value
        }));
        setArriveData((prevState) => ({
          ...prevState,
          CPH: holding.value
        }));
      }
    }

    if (helpers.option.transporter.isDepartureKeeper(session.transportedBy)) {
      setTransportDetails((prevState) => ({
        ...prevState,
        transportedBy: session.transportedBy,
        transporterName: ''
      }));
    } else if (helpers.option.transporter.isDestinationKeeper(session.transportedBy)) {
      setTransportDetails((prevState) => ({
        ...prevState,
        transportedBy: session.transportedBy,
        transporterName: ''
      }));
    } else {
      if (session.transportedBy) {
        setTransportDetails((prevState) => ({
          ...prevState,
          transportedBy: session.transportedBy
        }));
      }

      if (session.transporter) {
        setTransportDetails((prevState) => ({
          ...prevState,
          transporterName: session.transporter
        }));
      }
    }

    if (session.vehicleRegistrationNumber) {
      setTransportDetails((prevState) => ({
        ...prevState,
        vehicleRegistrationNumber: session.vehicleRegistrationNumber
      }));
    }

    if (session.authorisationNumber) {
      setTransportDetails((prevState) => ({
        ...prevState,
        transportAuthorisationNumber: session.authorisationNumber
      }));
    }

    if (session.batchList) {
      setBatchList(session.batchList);
      setBatchTotal(session.batchList.reduce((total, batch) => total + batch.animalTotal, 0));
    }

    if (session.individualAnimals) {
      setAnimalDetails(session.individualAnimals);
    }

    if (session.fciData) {
      setFciData(session.fciData);

      setAnimalDetails((prevState) => prevState.map((animal) => ({
        ...animal,
        unfit: session.fciData.unfitAnimals?.includes(animal.tagNumber)
      })));
    }
  };

  const clear = {
    errors: () => {
      setValidateErrors([]);
      setDepartureDateError(ERR_TEMPLATE);
      setArrivalDateError(ERR_TEMPLATE);
      setTransportDetailsError(ERR_TEMPLATE);
      setHaulierNameError(ERR_TEMPLATE);
      setTransportAuthNumberError(ERR_TEMPLATE);
    }
  };

  const validate = {
    date: (whichDate, date) => {
      let hasError = false;

      if (helpers.flags.isDeparture(whichDate)) {
        setDepartureDateError(ERR_TEMPLATE);
      } else if (helpers.flags.isDestination(whichDate)) {
        setArrivalDateError(ERR_TEMPLATE);
      }

      if (helpers.flags.isDeparture(whichDate)) {
        const dateIsXDayAheadOverLimit = helpers.date.is.xDaysInTheFutureOverLimit(date, departureDaysAhead);
        const departureDateFromIsAfterDestinationDate = helpers.date.is.afterDate(date, arriveData.date);
        const departureDateIsMoreThanOneDayBeforeArrivalDate = helpers.date.is.xPeriodAfterDate(arriveData.date, date, 1, constants.period.days);

        if (!hasError && dateIsXDayAheadOverLimit) {
          setDepartureDateError({ type: constants.errorAndWarning.error, value: dateIsXDayAheadOverLimit });

          hasError = true;
        }

        if (!hasError && departureDateFromIsAfterDestinationDate) {
          setDepartureDateError({ type: constants.errorAndWarning.error, value: 'error.departureDateCannotBeAfterArrivalDate' });

          hasError = true;
        }

        if (!hasError && departureDateIsMoreThanOneDayBeforeArrivalDate) {
          setDepartureDateError({ type: constants.errorAndWarning.error, value: 'movements.departure.moreThan1DayBeforeArrival' });
        }
      } else if (helpers.flags.isDestination(whichDate)) {
        const destinationDateIsInTheFuture = helpers.date.is.inTheFuture(date);
        const destinationDateIsBeforeDepartureDate = helpers.date.is.beforeDate(date, departData.date);
        const destinationDateIsMoreThanOneDayAfterDepartureDate = helpers.date.is.xPeriodAfterDate(date, departData.date, 1, constants.period.days);

        if (!hasError && destinationDateIsInTheFuture) {
          setArrivalDateError({ type: constants.errorAndWarning.error, value: destinationDateIsInTheFuture });

          hasError = true;
        }

        if (!hasError && destinationDateIsBeforeDepartureDate) {
          setArrivalDateError({ type: constants.errorAndWarning.error, value: 'error.arrivalDateCannotBeBeforeDepartureDate' });

          hasError = true;
        }

        if (!hasError && destinationDateIsMoreThanOneDayAfterDepartureDate) {
          setArrivalDateError({ type: constants.errorAndWarning.error, value: 'movements.arrival.moreThan1DayAfterDeparture' });
        }
      }
    },

    registrationNumber: (regNum) => {
      if (regNum && !regNum.match(/^[a-z0-9\s]+$/i)) {
        setVehicleRegNoError('error.registrationNumber');
      } else {
        setVehicleRegNoError('');
      }
    }
  };

  const getDates = {
    departureMin: () => {
      if (arriveData.date) {
        return helpers.date.formatYYYYMMDD(
          helpers.date.momentToJSDate(
            helpers.date.momentMinDate(
              helpers.date.subtractPeriod(arriveData.date, 1, constants.period.days),
              helpers.date.todayEndOfDay()
            )
          )
        );
      }

      return null;
    },

    departureMax: () => {
      if (helpers.option.movement.isOff(movementType)) {
        const daysAhead = new Date();
        daysAhead.setDate(daysAhead.getDate() + departureDaysAhead);

        return helpers.date.formatYYYYMMDD(daysAhead);
      }

      if (arriveData.date) {
        return helpers.date.formatYYYYMMDD(new Date(arriveData.date));
      }

      return helpers.date.formatYYYYMMDD(new Date());
    }
  };

  const pageContent = {
    animalBatch: () => {
      return (
        <AnimalsBatches
          batchList={batchList}
          batchTotal={batchTotal}
          pending={pending}
          resetForm={resetForm}
          setBatchList={setBatchList}
          setBatchTotal={setBatchTotal}
          setModal={setModal}
          setPending={setPending}
          updateBatchList={handleChanges.batchList}
        />
      );
    },

    departure: {
      location: () => {
        return (
          <>
            {!departData.CPH &&
              <>
                {(!inBusinessMovement || numberOfHoldings === 1) &&
                  <OtherHoldingSelection
                    autoSelect={true}
                    currentHolding={holding}
                    isGathering={true}
                    movementType={movementType}
                    otherHolding={{ value: departData.CPH }}
                    setInBusinessMovement={setInBusinessMovement}
                    setModal={setModal}
                    setMovementType={setMovementType}
                    setOtherHolding={handleChanges.departureCph}
                  />
                }

                {inBusinessMovement && numberOfHoldings > 1 &&
                  <div className={`section ${classes.cphAndDate}`}>
                    <DropdownHoldings
                      label="label.selectFromYourHoldingsDeparture"
                      name="dropdownHoldings"
                      onChange={(event) => handleChanges.departureCph(event.target.value.trim())}
                    />
                  </div>
                }
              </>
            }

            {departData.CPH &&
              <AddressSummary
                address1={departAddress}
                address2={arriveAddress}
                blockLayout={true}
                info1={departData.CPH}
                info2={holding.value}
                isGathering={true}
                label="label.selectedHoldings"
                label1="label.departureHoldingFrom"
                label2="label.destinationHoldingTo"
                onClick={() => {
                  storeService.session.remove.movementOtherHolding();
                  setDepartData((prevState) => ({
                    ...prevState,
                    CPH: '',
                    manualCPH: ''
                  }));
                }}
                queryKeeper={true}
                setAddress1={setDepartAddress}
                setAddress2={setArriveAddress}
                setModal={setModal}
                storeIn1="movementOtherHoldingAddress"
                storeIn2="holdingAddress"
              />
            }
          </>
        );
      },

      date: () => {
        return (
          <div className={`section ${classes.cphAndDate}`}>
            <InputDate
              dontValidate={true}
              error={departureDateError && helpers.response.isError(departureDateError.type)
                ? helpers.error.cfgDepartureDateError(departureDateError.value, departureDaysAhead)
                : ''}
              id="dateFrom"
              labelPosition={constants.labelPosition.above}
              labelText="label.dateDeparture"
              maxDate={getDates.departureMax()}
              minDate={getDates.departureMin()}
              monthsShown={2}
              onChange={(event) => handleChanges.departureDate(event.target.value)}
              setError={setDepartureDateError}
              value={departData.date}
              yearLength={4}
            />
          </div>
        );
      }
    },

    destination: {
      location: () => {
        return (
          <>
            {!arriveData.CPH &&
              <>
                {(!inBusinessMovement || numberOfHoldings === 1) &&
                  <OtherHoldingSelection
                    autoSelect={true}
                    currentHolding={holding}
                    isGathering={true}
                    movementType={movementType}
                    otherHolding={{ value: arriveData.CPH }}
                    setInBusinessMovement={setInBusinessMovement}
                    setModal={setModal}
                    setMovementType={setMovementType}
                    setOtherHolding={handleChanges.destinationCph}
                  />
                }

                {inBusinessMovement && numberOfHoldings > 1 &&
                  <div className={`section ${classes.cphAndDate}`}>
                    <DropdownHoldings
                      label="label.selectFromYourHoldingsDestination"
                      name="dropdownHoldings"
                      onChange={(event) => handleChanges.destinationCph(event.target.value.trim())}
                    />
                  </div>
                }
              </>
            }

            {arriveData.CPH &&
              <AddressSummary
                address1={arriveAddress}
                address2={departAddress}
                blockLayout={true}
                info1={holding.value}
                info2={arriveData.CPH}
                isGathering={true}
                label="label.selectedHoldings"
                label1="label.departureHoldingFrom"
                label2="label.destinationHoldingTo"
                onClick={() => {
                  storeService.session.remove.movementOtherHolding();
                  setArriveData((prevState) => ({
                    ...prevState,
                    CPH: ''
                  }));
                }}
                queryKeeper={true}
                setAddress1={setArriveAddress}
                setAddress2={setDepartAddress}
                setModal={setModal}
                storeIn1="holdingAddress"
                storeIn2="movementOtherHoldingAddress"
              />
            }
          </>
        );
      },

      date: () => {
        return (
          <div className={`section ${classes.cphAndDate}`}>
            <InputDate
              dontValidate={true}
              error={arrivalDateError && helpers.response.isError(arrivalDateError.type) ? arrivalDateError.value : ''}
              id="dateTo"
              labelPosition={constants.labelPosition.above}
              labelText="label.arrivalDate"
              maxDate={helpers.date.arrivalMax(departData.date, 1)}
              minDate={helpers.date.formatYYYYMMDD(new Date(departData.date))}
              monthsShown={2}
              onChange={(event) => handleChanges.arrivalDate(event.target.value)}
              setError={setArrivalDateError}
              value={arriveData.date}
              yearLength={4}
            />
          </div>
        );
      }
    },

    exemptions: () => {
      return (
        <Exemptions
          groupLabel="label.selectAllStandstillExemptionsThatApply"
          isAmend={isAmend}
          options={options}
          panelTitle="label.standstillExemptions"
          resetForm={resetForm}
          setDataChanged={dataHasChanged}
          setModal={setModal}
          setOptions={handleChanges.exemptions}
          speciesId={species.id}
        />
      );
    },

    fciDetails: () => {
      return (
        <FoodChainInformation
          animals={animalDetails}
          batchList={batchList}
          choiceDisabled={animalDetails.length === 0 && batchTotal === 0}
          disabledIcon={true}
          fciData={fciData}
          metFCIWithdrawalPeriods={metFCIWithdrawalPeriods}
          panelTitle="boApp.sectionTitles.fci"
          setAnimals={setAnimalDetails}
          setDataChanged={dataHasChanged}
          setFciData={setFciData}
          setModal={setModal}
          speciesId={species.id}
        />
      );
    },

    individualAnimals: () => {
      return (
        <AnimalsIndividuals
          addAnimals={handleChanges.addAnimals}
          animalDetails={animalDetails}
          animalDetailsValidation={animalDetailsValidation}
          pending={pending}
          removeAllInvalid={handleChanges.removeAllInvalid}
          removeAnimal={handleChanges.removeAnimal}
          removeAnimalsAll={handleChanges.removeAnimalsAll}
          resetForm={resetForm}
          setAnimalDetails={setAnimalDetails}
          setAnimalDetailsValidation={setAnimalDetailsValidation}
          setModal={setModal}
          setPending={setPending}
          species={species}
          uploadAnimalDetails={handleChanges.addAnimals}
        />
      );
    },

    marketType: () => {
      return (
        <div className="section">
          <RadioButtons
            id="marketType"
            ids={[
              constants.option.market.green,
              constants.option.market.red
            ]}
            inline={true}
            label="radioButtons.marketType.label"
            name="marketType"
            onChange={(event) => handleChanges.marketType(event.target.value)}
            value={marketType}
          />
        </div>
      );
    },

    movementType: () => {
      return (
        <MovementType
          inBusinessMovement={inBusinessMovement}
          isAmend={isAmend}
          movementType={String(movementType)}
          setInBusinessMovement={handleChanges.inBusinessMovement}
          setMovementType={handleChanges.movementType}
        />
      );
    },

    transportInformation: () => {
      return (
        <TransportDetails
          haulierNameError={haulierNameError}
          panelTitle="label.transportInformation"
          setAuthorisationNumber={handleChanges.authorisationNumber}
          setTransportAuthNumberError={setTransportAuthNumberError}
          setTransportedBy={handleChanges.transportedBy}
          setTransporterName={handleChanges.transporter}
          setVehicleRegistrationNumber={handleChanges.vehicleRegistrationNumber}
          transportAuthNumberError={transportAuthNumberError}
          transportDetails={transportDetails}
          transportDetailsError={transportDetailsError}
          vehicleRegNoError={vehicleRegNoError}
        />
      );
    },

    validationErrors: () => {
      const getErrorMessage = (inValue) => inValue > 0
        ? ' ' + inValue + ' ' + (inValue > 1 ? t('label.errors').toLowerCase() : t('label.error'))
        : '';

      return (
        <div className={classes.notification + ' ' + classes.alert}>
          <p className={classes.notificationTitle}><i className={'bi bi-x-square ' + classes.icon} /> {t('label.youHave')}{ } {getErrorMessage(validateErrors.length)} </p>
          {validateErrors.map((error, index) => (
            <p className={classes.error} key={error.code + index}>{error.code}{' : '}{error.message}</p>
          ))}
        </div>
      );
    }
  };

  const handleChanges = {
    addAnimals: (tags) => {
      const uniqueNewTags = tags.filter((tag) => !animalDetails.find((tagItem) => tagItem.tagNumber === tag.tagNumber));
      const animalDetailsCopy = [...animalDetails, ...uniqueNewTags].map((item) => ({
        ...item,
        tagNumber: item.tagNumber
      }));
      storeService.session.set.movementAnimalsBeingMoved(animalDetailsCopy);
      setAnimalDetails(animalDetailsCopy.map((animal) => ({
        ...animal,
        unfit: false
      })));

      dataHasChanged();
    },

    arrivalDate: (date) => {
      storeService.session.set.movementArrivalDate(date);
      setArriveData((prevState) => ({
        ...prevState,
        date
      }));
      validate.date(constants.flags.destination, date);

      dataHasChanged();
    },

    authorisationNumber: (authNum) => {
      storeService.session.set.movementAuthorisationNumber(authNum);
      setTransportDetails((prevState) => ({
        ...prevState,
        transportAuthorisationNumber: authNum
      }));

      dataHasChanged();
    },

    batchList: (batches) => {
      storeService.session.set.movementBatchList(batches.map((batch) => ({
        ...batch,
        added: true
      })));
      setBatchList(batches.map((batch) => ({
        ...batch,
        added: true
      })));

      dataHasChanged();
    },

    clearTransporterName: () => {
      setTransportDetails((prevState) => ({
        ...prevState,
        transporterName: ''
      }));
    },

    departureCph: (cph) => {
      storeService.session.set.movementOtherHolding(cph);
      setDepartData((prevState) => ({
        ...prevState,
        CPH: cph.value ?? cph
      }));

      resetAddresses();
      dataHasChanged();

      if (transportDetails.transportedBy) {
        if (helpers.option.transporter.isDepartureKeeper(transportDetails.transportedBy)) {
          const addressCheck = setInterval(() => {
            if (storeService.session.get.movementOtherHoldingAddress()) {
              handleChanges.transportedBy(transportDetails.transportedBy);
              clearInterval(addressCheck);
            }
          }, 500);
        } else if (helpers.option.transporter.isDestinationKeeper(transportDetails.transportedBy)) {
          const addressCheck = setInterval(() => {
            if (storeService.session.get.holding()?.address) {
              handleChanges.transportedBy(transportDetails.transportedBy);
              clearInterval(addressCheck);
            }
          }, 500);
        }
      }
    },

    departureDate: (date) => {
      storeService.session.set.movementDepartureDate(date);
      setDepartData((prevState) => ({
        ...prevState,
        date
      }));
      validate.date(constants.flags.departure, date);

      dataHasChanged();
    },

    destinationCph: (cph) => {
      storeService.session.set.movementOtherHolding(cph);
      setArriveData((prevState) => ({
        ...prevState,
        CPH: cph.value ?? cph
      }));

      resetAddresses();
      dataHasChanged();

      if (transportDetails.transportedBy) {
        if (helpers.option.transporter.isDepartureKeeper(transportDetails.transportedBy)) {
          const addressCheck = setInterval(() => {
            if (storeService.session.get.holding()?.address) {
              handleChanges.transportedBy(transportDetails.transportedBy);
              clearInterval(addressCheck);
            }
          }, 500);
        } else if (helpers.option.transporter.isDestinationKeeper(transportDetails.transportedBy)) {
          const addressCheck = setInterval(() => {
            if (storeService.session.get.movementOtherHoldingAddress()) {
              handleChanges.transportedBy(transportDetails.transportedBy);
              clearInterval(addressCheck);
            }
          }, 500);
        }
      }
    },

    exemptions: (exemptionsList) => {
      setOptions(exemptionsList);
      if (Array.isArray(exemptionsList)) {
        const selectedExemption = exemptionsList.filter((item) => item.value).map((item) => item);

        if (selectedExemption.length > 0) {
          storeService.session.set.movementExemptions(exemptionsList);
          dataHasChanged();
        }
      }
    },

    inBusinessMovement: (inBusiness) => {
      if (inBusiness) {
        storeService.session.set.movementInBusiness(true);
      } else {
        storeService.session.remove.movementInBusiness();
      }
      storeService.session.remove.movementOtherHolding();
      setDepartData({
        CPH: helpers.option.movement.isOff(movementType) ? holding.value : '',
        date: '',
        manualCPH: ''
      });
      setArriveData({
        CPH: helpers.option.movement.isOn(movementType) ? holding.value : '',
        date: ''
      });
      setInBusinessMovement(inBusiness);

      dataHasChanged();
    },

    marketType: (type) => {
      storeService.session.set.movementMarketType(type);
      setMarketType(type);

      dataHasChanged();
    },

    movementType: (type) => {
      storeService.session.set.movementType(type);
      setMovementType(type);

      dataHasChanged();

      if (helpers.option.movement.isOff(type) && !inBusinessMovement) {
        storeService.session.remove.movementArrivalDate();
        setArriveData((prevState) => ({
          ...prevState,
          date: ''
        }));
        setArrivalDateError(ERR_TEMPLATE);
      }

      if (!isAmend) {
        const oldDepartCPH = departData.CPH;
        const oldArriveCPH = arriveData.CPH;

        setDepartData((prevState) => ({
          ...prevState,
          CPH: oldArriveCPH
        }));

        if (helpers.option.movement.isOff(movementType) && !inBusinessMovement) {
          setArriveData((prevState) => ({
            ...prevState,
            CPH: oldDepartCPH,
            date: ''
          }));
        } else {
          setArriveData((prevState) => ({
            ...prevState,
            CPH: oldDepartCPH
          }));
        }
      }
    },

    removeAnimal: (animal) => {
      setAnimalDetails((prevState) => {
        const newAnimalsDetails = helpers.tag.remove([...prevState], animal);

        if (newAnimalsDetails.length === 0) {
          storeService.session.remove.movementAnimalsBeingMoved();
        } else {
          storeService.session.set.movementAnimalsBeingMoved(newAnimalsDetails);
        }

        return newAnimalsDetails;
      });

      dataHasChanged();

      setTimeout(() => {
        document.activeElement.blur();
      }, 1);
    },

    removeAnimalsAll: () => {
      storeService.session.set.movementAnimalsBeingMoved([]);
      setAnimalDetails([]);

      dataHasChanged();
    },

    removeAllInvalid: () => {
      const animalDetailsCopy = helpers.animal.removeAllInvalid(animalDetails);
      storeService.session.set.movementAnimalsBeingMoved(animalDetailsCopy);
      setAnimalDetails(animalDetailsCopy);

      dataHasChanged();
    },

    transportedBy: (transportedBy) => {
      storeService.session.set.movementTransportedBy(transportedBy);
      setTransportDetails((prevState) => ({
        ...prevState,
        transportedBy
      }));

      dataHasChanged();

      handleChanges.clearTransporterName();
    },

    transporter: (transporter) => {
      storeService.session.set.movementTransporter(transporter);
      setTransportDetails((prevState) => ({
        ...prevState,
        transporterName: transporter
      }));

      dataHasChanged();
    },

    vehicleRegistrationNumber: (regNum) => {
      storeService.session.set.movementVehicleRegistrationNumber(regNum);
      setTransportDetails((prevState) => ({
        ...prevState,
        vehicleRegistrationNumber: regNum
      }));

      validate.registrationNumber(regNum);

      dataHasChanged();
    },

    withdrawalPeriod: (period) => {
      storeService.session.set.movementFciWithdrawalPeriod(period);

      dataHasChanged();
    }
  };

  const confirmation = {
    button: {
      recordAnother: () => ({
        id: 'recordAnother',
        label: 'button.recordAnotherMovement',
        onClick: () => {
          setSubmitted(false);
          setIsAmend(false);
          setValidationError(false);
          setValidateErrors([]);
          resetPage();
          history.push(navigation.movements());
        }
      }),

      returnToHomePage: () => ({
        id: 'returnToHomePage',
        label: 'button.returnToHomePage',
        onClick: returnTo.homePage
      }),

      returnToUserActivity: () => ({
        id: 'returnToUserActivity',
        label: 'button.returnToUserActivity',
        onClick: returnTo.userActivity
      }),

      viewSummary: (requestId, movementRef, requestType) => ({
        id: 'viewSummary',
        label: 'button.viewSummary',
        onClick: () => {
          setPermission(userRoleType.GENERIC);
          storeService.session.set.permissions(userRoleType.GENERIC);
          storeService.session.remove.holding();

          setTimeout(() => {
            history.push({
              pathname: Routing.activityDetails + requestId,
              state: {
                data: {
                  requestId,
                  movementDocumentRef: movementRef,
                  requestType,
                  current: {
                    returnUrl: permissions.isKeeper() ? Routing.keeperHolding : navigation.movements(),
                    permissions: role,
                    holding
                  }
                }
              }
            });
          }, [100]);
        }
      })
    },

    link: {
      pdf: (speciesId, ref) => (
        {
          label: helpers.species.isSheepId(speciesId) || helpers.species.isGoatId(speciesId) ? 'button.printMovementDocument' : null,
          param: ref,
          type: constants.linkTypes.pdfButton
        }
      )
    }
  };

  const returnTo = {
    homePage: () => {
      storeService.session.remove.dataChanged();
      storeService.session.set.permissions(userRoleType.GENERIC);
      setPermission(userRoleType.GENERIC);
      history.push(Routing.home);
    },

    movementSummary: () => {
      history.push(location.state.returnUrl, {
        data: location.state.data,
        searchState: location.state.searchState
      });
    },

    userActivity: () => {
      setIsAmend(false);
      setValidationError(false);
      setValidateErrors([]);
      resetPage();
      storeService.session.set.permissions(userRoleType.GENERIC);
      setPermission(userRoleType.GENERIC);
      history.push(Routing.userActivity);
    }
  };

  const resetPage = () => {
    setPending(false);
    setMovementRef(null);
    setPollingStatus(null);
    setDataChanged(false);
    setAmendChangedData(false);

    if (!submitted && isAmend) {
      const sessionMovementOriginal = storeService.session.get.originalMovement();
      storeService.session.set.movement(sessionMovementOriginal);

      const sessionMovementType = sessionMovementOriginal.movementType;
      const sessionMovementInBusiness = sessionMovementOriginal.movementInBusiness;
      const sessionDepartureDate = sessionMovementOriginal.departureDate;
      const sessionArrivalDate = sessionMovementOriginal.arrivalDate;
      const sessionOtherHolding = sessionMovementOriginal.otherHolding;
      const sessionTransportedBy = sessionMovementOriginal.transportInformation.transportedBy;
      const sessionTransporter = sessionMovementOriginal.transportInformation.transporter;
      const sessionVehicleRegistrationNumber = sessionMovementOriginal.transportInformation.vehicleRegistrationNumber;
      const sessionAuthorisationNumber = sessionMovementOriginal.transportInformation.authorisationNumber;
      const sessionBatchList = sessionMovementOriginal.selectedAnimalDetails.batchList;
      const sessionIndividualAnimals = sessionMovementOriginal.selectedAnimalDetails.animalsBeingMoved;
      const sessionFciData = sessionMovementOriginal.fci;

      setMarketType(constants.option.market.green);

      setDataFromSessionStorage({
        arrivalDate: sessionArrivalDate,
        authorisationNumber: sessionAuthorisationNumber,
        batchList: sessionBatchList,
        departureDate: sessionDepartureDate,
        fciData: sessionFciData,
        individualAnimals: sessionIndividualAnimals,
        movementInBusiness: sessionMovementInBusiness,
        movementType: sessionMovementType,
        otherHolding: sessionOtherHolding,
        transportedBy: sessionTransportedBy,
        transporter: sessionTransporter,
        vehicleRegistrationNumber: sessionVehicleRegistrationNumber
      });
    } else {
      setMarketType(constants.option.market.green);
      setMovementType(constants.option.movement.on);
      setInBusinessMovement(false);

      setDepartData({
        CPH: '',
        date: '',
        manualCPH: ''
      });
      setArriveData({
        CPH: holding.value,
        date: ''
      });
      setTransportDetails({
        transportedBy: '',
        transportVehicleRegistrationNo: '',
        transporterName: '',
        transportAuthorisationNumber: ''
      });
      setBatchList([]);
      setBatchTotal(0);
      setAnimalDetails([]);

      setSubmitted(false);
      setIsAmend(false);
      storeService.session.remove.activeTabId();
      storeService.session.removeAll.movement();
      storeService.session.removeAll.original();

      const dateFromField = document.getElementById('dateFrom');
      const dateToField = document.getElementById('dateTo');
      if (dateFromField) {
        dateFromField.value = '';
      }
      if (dateToField) {
        dateToField.value = '';
      }
    }

    resetAddresses();
    setResetForm(!resetForm);
    setValidateErrors([]);
    setValidationError(false);
  };

  const submitMovement = (e) => {
    e.preventDefault();
    clear.errors();
    setSubmitted(true);
    setPending(true);
    setValidationError(false);
    setValidateErrors([]);
    storeService.session.removeAll.confirm();

    const mapResponse = (processArea) => {
      if (processArea) {
        storeService.session.set.confirmRequestType(isAmend ? constants.option.requestType.correctTransfer : constants.option.requestType.movement);
        const responseRequestId = processArea.requestId !== undefined ? String(processArea.requestId) : null;
        storeService.session.set.confirmRequestId(responseRequestId);
        setRequestId(responseRequestId);
        const responseStatus = bffStatus.getStatus(processArea.requestStatus);
        storeService.session.set.confirmPollingStatus(responseStatus);
        setPollingStatus(responseStatus);
        storeService.session.set.confirmPollingErrors(processArea.errors ?? []);
        storeService.session.set.confirmPollingWarnings(processArea.warnings ?? []);
        const responseMovementRef = processArea.content?.movementDocument?.movementDocumentRef ? String(processArea.content.movementDocument.movementDocumentRef) : null;
        storeService.session.set.confirmMovementReference(responseMovementRef);
        setMovementRef(responseMovementRef);
      }
    };

    const transportInformation = (transportDetails && transportDetails.transportedBy !== '')
      ? {
        recordTransportInformation: 'yes',
        transportedBy: transportDetails.transportedBy,
        transporter: transportDetails.transporterName,
        vehicleRegistrationNumber: transportDetails.vehicleRegistrationNumber,
        authorisationNumber: transportDetails.transportAuthorisationNumber
      } : {
        recordTransportInformation: 'no'
      };

    const tempParams = {
      poll: config.POLLS_ENABLED,
      altErrors: true,
      inBusinessMovement,
      movementType: inBusinessMovement ? constants.option.movement.on : movementType,
      userHolding: inBusinessMovement ? arriveData.CPH : holding.value,
      animalCount: String(batchTotal + animalDetails.length),
      species: species.name,
      departureDate: departData.date,
      arrivalDate: arriveData.date,
      transportInformation: transportInformation,
      finalAnimalDetails: {
        animalsBeingMoved: animalDetails,
        batchList: batchList,
        numberOfAnimalsSelected: String(batchTotal + animalDetails.length)
      },
      fciData,
      exemptions: options.filter((o) => o.value),
      sourceHolding: departData.CPH ?? (helpers.species.isCattleId(species.id) ? config.UNKNOWN : ''),
      destinationHolding: arriveData.CPH
    };

    if (isAmend) {
      tempParams.amend = true;
      tempParams.originalMovementId = sessionMovement.movementId;
    } else {
      tempParams.amend = false;
    }

    bff
      .post('/movement', tempParams)
      .then((res) => {
        storeService.session.remove.dataChanged();
        const { data } = res;
        const { poll } = data;

        if (data.errorCode && data.errorCode === 400) {
          setValidationError(true);
          setValidateErrors(data.errors ?? []);
          setPollingStatus(constants.status.error);
        } else if (poll?.requestStatus) {
          mapResponse(res.data.poll);
        } else if (data.requestStatus) {
          mapResponse(res.data);
        } else {
          setPollingStatus(null);
          storeService.session.set.confirmPollingStatus(null);
        }
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        errors.BFF(error, setModal);
      });
  };

  const submissionConfirmation = () => {
    const sessionErrors = storeService.session.get.confirmPollingErrors();
    const sessionWarnings = storeService.session.get.confirmPollingWarnings();
    const withErrorsOrWarnings = !validationError && (sessionErrors?.length > 0 || sessionWarnings?.length > 0);
    const requestType = isAmend ? constants.option.requestType.correctTransfer : constants.option.requestType.movement;

    return (
      <Confirmation
        buttons={[
          withErrorsOrWarnings || isAmend ? confirmation.button.returnToUserActivity() : null,
          withErrorsOrWarnings ? confirmation.button.returnToHomePage() : null,
          !withErrorsOrWarnings ? confirmation.button.recordAnother() : null,
          requestId && movementRef ? confirmation.button.viewSummary(requestId, movementRef, requestType) : null
        ].filter((item) => item)}
        confirm={{
          id: movementRef,
          label: 'label.movementRef',
          onClick: bffStatus.isPending(pollingStatus) || bffStatus.isClaPending(pollingStatus)
            ? () => {
              storeService.session.remove.dataChanged();
              storeService.session.set.permissions(userRoleType.GENERIC);
              setPermission(userRoleType.GENERIC);
              history.push(Routing.userActivity);
            }
            : null
        }}
        errors={sessionErrors ?? []}
        label={bffStatus.isClaPending(pollingStatus)
          ? 'label.submissionPending'
          : (sessionErrors?.length > 0
            ? 'label.submissionError'
            : (isAmend
              ? 'label.movementAmendmentSubmitted'
              : 'label.movementSubmitted'
            )
          )
        }
        links={[
          movementRef ? confirmation.link.pdf(species.id, { movementRef, requestId }) : null
        ].filter((item) => item)}
        setModal={setModal}
        status={pollingStatus}
        warnings={sessionWarnings ? sessionWarnings : []}
      />
    );
  };

  const checkSubmitButtonStatus = () => {
    if (isAmend) {
      const origMovementData = cloneDeep(storeService.session.get.originalMovement());
      const tempMovementData = cloneDeep(storeService.session.get.movement());

      let hasErrors = false;

      if (tempMovementData) {
        delete tempMovementData.marketType;

        // if (tempMovementData?.selectedAnimalDetails?.batchList?.length > 0) {
        //   tempMovementData.selectedAnimalDetails.batchList.forEach((batch) => delete batch.added);
        //   helpers.sortArrayOfObjects(tempMovementData.selectedAnimalDetails.batchList, 'batchNumber', constants.sorting.ascending);
        // }
        // if (tempMovementData?.selectedAnimalDetails?.animalsBeingMoved?.length > 0) {
        //   tempMovementData.selectedAnimalDetails.animalsBeingMoved.forEach((animal) => delete animal.added);
        //   helpers.sortArrayOfObjects(tempMovementData.selectedAnimalDetails.animalsBeingMoved, 'tagNumber', constants.sorting.ascending);
        // }

        if (tempMovementData?.otherHolding) {
          delete tempMovementData.otherHolding.address;
        }

        const selectedTempExemption = tempMovementData.exemptions.filter((item) => item.value).map((item) => item);
        if (tempMovementData?.exemptions && selectedTempExemption.length > 0) {
          // eslint-disable-next-line no-unused-vars
          tempMovementData.exemptions = tempMovementData.exemptions.map(({ disabled, ...item }) => item);
        }
        if (origMovementData?.exemptions) {
          // eslint-disable-next-line no-unused-vars
          origMovementData.exemptions = origMovementData.exemptions.map(({ disabled, ...item }) => item);
        }

        if (tempMovementData?.transportDetails?.transportVehicleRegistrationNo && vehicleRegNoError) {
          hasErrors = true;
        }

        // helpers.checkObjectEquality(origMovementData, tempMovementData);

        const changesPresent = !isEqual(origMovementData, tempMovementData);
        setAmendChangedData(changesPresent);

        if (changesPresent && !hasErrors) {
          setSubmitDisabled(false);
        } else {
          setSubmitDisabled(true);
        }
      }
    } else if (
      departData.CPH &&
      departData.date &&
      !departureDateError.value &&
      arriveData.CPH &&
      (
        !transportDetails.transportVehicleRegistrationNo ||
        (
          transportDetails.transportVehicleRegistrationNo &&
          !vehicleRegNoError
        )
      ) &&
      (
        (
          helpers.option.movement.isOff(movementType) &&
          !inBusinessMovement
        ) ||
        (
          (
            helpers.option.movement.isOn(movementType) ||
            (
              helpers.option.movement.isOff(movementType) &&
              inBusinessMovement
            )
          ) &&
          arriveData.date &&
          !arrivalDateError.value
        )
      ) &&
      (
        (
          batchList.length > 0 &&
          !batchList.some((batch) => helpers.batch.isInvalid(batch))
        ) ||
        (
          animalDetails.length > 0 &&
          !animalDetails.some((batch) => helpers.batch.isInvalid(batch))
        )
      ) &&
      (
        !fciData.recordFCI || helpers.option.fci.doNotRecord(fciData.recordFCI) ||
        (
          helpers.option.fci.doRecord(fciData.recordFCI) &&
          (
            helpers.option.animals.doSatisfy(fciData.animalsSatisfy) ||
            (
              helpers.option.animals.doNotSatisfy(fciData.animalsSatisfy) &&
              fciData.withdrawalPeriod?.code &&
              fciData.nonComplianceReason &&
              fciData.unfitAnimals?.length > 0
            )
          )
        )
      )
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  React.useEffect(() => {
    checkSubmitButtonStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    marketType,
    movementType,
    inBusinessMovement,
    departData,
    arriveData,
    transportDetails,
    options,
    batchList,
    animalDetails,
    fciData
  ]);

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    helpers.get.metFciWithdrawalPeriods(source.token)
      .then(({ data }) => {
        if (helpers.response.isValid(data, setModal)) {
          setMetFCIWithdrawalPeriods(data);
        }
      });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const routing = Routing[role + 'Movements'];
    if (routing) {
      const unListen = history.listen((path) => {
        if (path.pathname.substring(0, routing.length) !== Routing[role + 'Movements']) {
          storeService.session.remove.activeTabId();
          storeService.session.remove.dataChanged();
          storeService.session.removeAll.confirm();
          storeService.session.removeAll.movement();
          storeService.session.removeAll.original();
          unListen();
        }
      });
    }

    window.onpopstate = () => {
      if (storeService.session.get.confirm()) {
        history.push(routing);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  React.useEffect(() => {
    validate.registrationNumber(transportDetails.transportVehicleRegistrationNo);

    checkSubmitButtonStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transportDetails.transportVehicleRegistrationNo]);

  React.useEffect(() => {
    helpers.scrollToTop();
  }, []);

  React.useEffect(() => {
    const sessionMovementType = storeService.session.get.movementType();
    const sessionMovementInBusiness = storeService.session.get.movementInBusiness();
    const sessionDepartureDate = storeService.session.get.movementDepartureDate();
    const sessionArrivalDate = storeService.session.get.movementArrivalDate();
    const sessionOtherHolding = storeService.session.get.movementOtherHolding();
    const sessionTransportedBy = storeService.session.get.movementTransportedBy();
    const sessionTransporter = storeService.session.get.movementTransporter();
    const sessionVehicleRegistrationNumber = storeService.session.get.movementVehicleRegistrationNumber();
    const sessionAuthorisationNumber = storeService.session.get.movementAuthorisationNumber();
    const sessionBatchList = storeService.session.get.movementBatchList();
    const sessionIndividualAnimals = storeService.session.get.movementAnimalsBeingMoved();
    const sessionFciData = storeService.session.get.movementFci();

    setDataFromSessionStorage({
      arrivalDate: sessionArrivalDate,
      authorisationNumber: sessionAuthorisationNumber,
      batchList: sessionBatchList,
      departureDate: sessionDepartureDate,
      fciData: sessionFciData,
      individualAnimals: sessionIndividualAnimals,
      movementInBusiness: sessionMovementInBusiness,
      movementType: sessionMovementType,
      otherHolding: sessionOtherHolding,
      transportedBy: sessionTransportedBy,
      transporter: sessionTransporter,
      vehicleRegistrationNumber: sessionVehicleRegistrationNumber
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ready &&
        <>
          {(!submitted ||
            (
              submitted && !pending && validateErrors.length > 0
            )
          ) &&
            <>
              <Prompt
                message={(params) => {
                  const message = isAmend ? 'warning.wantToExitAmendment' : 'warning.unsavedChanges2';
                  return (!storeService.session.get.idle() && ((!isAmend && dataChanged) || (isAmend && amendChangedData && location.pathname !== Routing[role + 'MovementConfirm'])) && params.pathname.substring(0, Routing[role + 'Movements'].length) !== Routing[role + 'Movements']) ? t(message) : true;
                }}
              />

              {submitted && !pending && validateErrors.length > 0 && // Submission error
                <Confirmation
                  errors={validateErrors}
                  label="label.submissionError"
                  setModal={setModal}
                  status={pollingStatus}
                />
              }

              <h1 className="h1">
                {t('menu.movements')}
                {isAmend &&
                  <> ({t('label.amendment')})</>
                }
              </h1>

              {isMarket && pageContent.marketType()}
              {isGathering && pageContent.movementType()}

              <h3 className="h3">
                {helpers.option.movement.isOn(movementType)
                  ? t('movements.departure.title')
                  : t('movements.destination.title')}
              </h3>

              {!inBusinessMovement &&
                <p className="p">
                  {helpers.option.movement.isOn(movementType)
                    ? t('movements.departure.descOffGathering')
                    : t('movements.destination.descOnGathering')
                  }
                </p>
              }

              {helpers.option.movement.isOn(movementType) && pageContent.departure.location()}
              {helpers.option.movement.isOff(movementType) && pageContent.destination.location()}

              <div className={[
                classes.datesSection,
                (
                  (helpers.option.movement.isOn(movementType) && departData.CPH) ||
                  (helpers.option.movement.isOff(movementType) && arriveData.CPH)
                )
                  ? classes.hasAddresses
                  : null
              ].join(' ').trim(' ')}>
                {pageContent.departure.date()}
                {(helpers.option.movement.isOn(movementType) || inBusinessMovement) && pageContent.destination.date()}
              </div>

              {pageContent.transportInformation()}
              {pageContent.exemptions()}
              {((isAbattoir && !helpers.species.isCattleId(species.id)) || isGathering) &&
                pageContent.animalBatch()
              }
              {pageContent.individualAnimals()}
              {pageContent.fciDetails()}
              <h2 className={classes.totalAnimals}>{t('label.animalTotal')} {String(batchTotal + animalDetails.length)}</h2>
              {validateErrors?.length > 0 && pageContent.validationErrors()}

              <div className="actions">
                <div className="left">
                  <Button
                    buttonType="primary"
                    disabled={submitDisabled}
                    id="movementSubmitButton"
                    label={isAmend ? 'button.submitAmendment' : 'button.submit'}
                    onClick={submitMovement}
                  />
                </div>
                <div className="right">
                  <Button
                    buttonType="tertiary"
                    disabled={!dataChanged && !amendChangedData}
                    id="resetButton"
                    label={isAmend ? 'label.revertChanges' : 'button.reset'}
                    onClick={() => setModal({
                      labelCancel: 'button.cancel',
                      modalTitle: 'label.revertChanges',
                      modalMessage: 'label.revertChangesConfirm',
                      modalConfirm: () => {
                        resetPage();
                        setModal(null);
                      },
                      modalClose: () => false
                    })}
                  />

                  {isAmend &&
                    <Button
                      buttonType="tertiary"
                      id="exitButton"
                      label="button.exitNoChanges"
                      onClick={returnTo.movementSummary}
                    />
                  }
                </div>
              </div>
            </>
          }

          {submitted && pending &&
            <Confirmation
              label="label.submissionPending"
              setModal={setModal}
              status={constants.status.pending}
            />
          }

          {submitted && !pending && !validationError && submissionConfirmation()}
        </>
      }
    </>
  );
};

MovementsGatherings.propTypes = {
  location: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  setPermission: PropTypes.func.isRequired
};

export default MovementsGatherings;
