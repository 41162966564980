import constants from 'services/constants';

export const regionCodes = {
  england: 'en',
  scotland: 'sc',
  wales: 'cy',
  test: 'test'
};

const regionalCPHs = [
  { region: regionCodes.england, cphList: Array(9).fill().map((element, index) => '0' + String(index + 1)) },
  { region: regionCodes.england, cphList: Array(42).fill().map((element, index) => String(index + 10)) },
  { region: regionCodes.scotland, cphList: Array(20).fill().map((element, index) => String(index + 52)) },
  { region: regionCodes.wales, cphList: Array(27).fill().map((element, index) => String(index + 72)) },
  { region: regionCodes.test, cphList: ['00', '99'] }
];

// find the holdings within specific region or regions
export const holdingHelper = {
  findHoldings: (data, regions) => {
    if (!regions) {
      return data;
    }

    const regionsList = [...(regions || [])];
    regionsList.push(regionCodes.test);

    const allMatchedRegions = [];

    for (let i = 0; i < regionsList.length; i++) {
      const matchedRegion = regionalCPHs.filter((regionalCPH) => regionalCPH.region === regionsList[i]);

      if (matchedRegion) {
        matchedRegion.forEach((region) => {
          allMatchedRegions.push(...region.cphList);
        });
      }
    }

    if (allMatchedRegions.length > 1) {
      return data.filter((holding) => allMatchedRegions.includes(holding.id.split('/')[0]));
    }

    return data;
  },

  findRegion: (cph) => {
    if (!cph || cph.length < 10) {
      return null;
    }
    let startCphCode;
    try {
      startCphCode = cph.split('/')[0];
      const matchedRegion = regionalCPHs.find((regionalCPH) => regionalCPH.cphList.includes(startCphCode));
      return (matchedRegion) ? matchedRegion.region : null;
    } catch (e) {
      return null;
    }
  },

  region: {
    isEngland: (region) => region === regionCodes.england,

    isWales: (region) => region === regionCodes.wales,

    isScotland: (region) => region === regionCodes.scotland,

    isTest: (region) => region === regionCodes.test
  },

  cph: {
    isEnglish: (cph) => cph ? holdingHelper.region.isEngland(holdingHelper.findRegion(cph)) : false,

    isNonCph: (cph) => cph === constants.cph.none,

    isNotEnglish: (cph) => cph ? cph > '51/999/9999' : false,

    isWelsh: (cph) => cph ? holdingHelper.region.isWales(holdingHelper.findRegion(cph)) : false,

    isScottish: (cph) => cph ? holdingHelper.region.isScotland(holdingHelper.findRegion(cph)) : false,

    isTest: (cph) => cph ? holdingHelper.region.isTest(holdingHelper.findRegion(cph)) : false,

    isUnknownCph: (cph) => cph === constants.cph.unknown,
    isCommonLand: (cph) => cph ? cph.substring(0, 2) === '00' : false
  }
};
