import Routing from 'routing';

const speciesId = {
  UNKNOWN: 0,
  SHEEP: 1,
  GOATS: 2,
  CATTLE: 3,
  ALPACAS: 4,
  PIGS: 5,
  DEER: 6,
  HORSES: 7
};

const internalSpeciesLabel = {
  UNKNOWN: 'Unknown',
  SHEEP: 'Sheep',
  GOATS: 'Goats',
  CATTLE: 'Cattle',
  ALPACAS: 'Alpacas',
  PIGS: 'Pigs',
  DEER: 'Deer',
  HORSES: 'Horses'
};

const speciesName = {
  UNKNOWN: 'animal-plural.' + speciesId.UNKNOWN.toString(),
  SHEEP: 'animal-plural.' + speciesId.SHEEP.toString(),
  GOATS: 'animal-plural.' + speciesId.GOATS.toString(),
  CATTLE: 'animal-plural.' + speciesId.CATTLE.toString(),
  ALPACAS: 'animal-plural.' + speciesId.ALPACAS.toString(),
  PIGS: 'animal-plural.' + speciesId.PIGS.toString(),
  DEER: 'animal-plural.' + speciesId.DEER.toString(),
  HORSES: 'animal-plural.' + speciesId.HORSES.toString()
};

const speciesBabyName = {
  UNKNOWN: 'baby.' + speciesId.UNKNOWN.toString(),
  SHEEP: 'baby.' + speciesId.SHEEP.toString(),
  GOATS: 'baby.' + speciesId.GOATS.toString(),
  CATTLE: 'baby.' + speciesId.CATTLE.toString(),
  ALPACAS: 'baby.' + speciesId.ALPACAS.toString(),
  PIGS: 'baby.' + speciesId.PIGS.toString(),
  DEER: 'baby.' + speciesId.DEER.toString(),
  HORSES: 'baby.' + speciesId.HORSES.toString()
};

export const fieldWidthType = {
  HALF: 'half',
  QUARTER: 'quarter'
};

const requestType = {
  adjacentMovement: 240,
  animalsAdded: 246,
  annualInventoryCreate: 141,
  annualInventoryDelete: 142,
  birth: 165,
  correctTransfer: 15,
  death: 31,
  holdingProductionTypeCreate: 241,
  holdingProductionTypeDelete: 242,
  movement: 4,
  movementHandshake: 145,
  paperMovementAdd: 229,
  paperMovementEdit: 238,
  tagApplication: 10,
  tagReplacement: 14,
  undo: 32
};

const constants = {
  address: {
    empty: 'No Address',
    format: {
      full: 'full',
      short: 'short',
      line: 'line',
      inline: 'inline',
      keeper: 'keeper',
      none: 'none',
      onlyAddress: 'onlyAddress'
    },
    status: {
      BFF_ERROR1: 'ERROR RESPONSE',
      BFF_ERROR2: 'Not Found',
      NO_NAME: 'label.noName',
      NO_TYPE: 'label.noType',
      NO_ADDRESS: 'label.noAddress',
      NOT_FOUND: 'label.cphNotFound',
      ERROR: 'ERROR',
      ERROR_NOT_FOUND: 'uiError.404address',
      FOUND_WITHOUT_ADDRESS: 'label.cphFoundWithoutAddress',
      CPH_NOT_LINKED: 'label.cphNotLinked',
      FETCH: 'label.fetchingData'
    }
  },

  abattoirTag: {
    maxTagLimit: 200
  },

  bo: {
    holdingActivity: {
      eventTypes: {
        adjacentMovements: {
          label: 'label.adjacentMovements',
          url: Routing.boHoldingActivityAdjacentMovements,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          endpoint: '/adjacentMovements'
        },
        // animalsAdded: {
        //   label: 'label.animalsAdded',
        //   url: Routing.boHoldingActivityAnimalsAdded,
        //   showDateRange: true,
        //   showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
        //   speciesRequired: true,
        //   endpoint: '/animalsAdded'
        // },
        animalsOnHolding: {
          label: 'label.animalsOnHolding',
          url: Routing.boHoldingActivityAnimalsOnHolding,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          showDate: true,
          speciesRequired: true,
          endpoint: '/animals'
        },
        annualInventory: {
          label: 'label.annualInventory',
          url: Routing.boHoldingActivityAnnualInventory,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS],
          endpoint: '/annualInventory'
        },
        associatedHoldings: {
          label: 'label.associatedHoldings',
          url: Routing.boHoldingActivityAssociatedHoldings,
          endpoint: '/holdingsInBusiness'
        },
        deaths: {
          label: 'label.deaths',
          url: Routing.boHoldingActivityDeaths,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          endpoint: '/holdingRegister'
        },
        holdingDetails: {
          label: 'label.holdingDetails',
          url: Routing.boHoldingActivityHoldingDetails,
          endpoint: '/holdingDetails',
          useComponent: 'BoHoldingActivity'
        },
        movements: {
          label: 'label.movements',
          url: Routing.boHoldingActivityMovements,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          endpoint: '/holdingRegister'
        },
        pendingReviews: {
          label: 'label.pendingReviews',
          url: Routing.boHoldingActivityPendingReviews,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          speciesRequired: true,
          endpoint: '/notifications'
        },
        tagReplacements: {
          label: 'label.replacementTags',
          url: Routing.boHoldingActivityTaggingReplacement,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          endpoint: '/holdingRegister'
        },
        tagApplications: {
          label: 'label.tagApplications',
          url: Routing.boHoldingActivityTaggingApplications,
          showDateRange: true,
          showSpecies: [internalSpeciesLabel.SHEEP, internalSpeciesLabel.GOATS, internalSpeciesLabel.DEER],
          endpoint: '/holdingRegister'
        },
        vaRecords: {
          label: 'label.vaRecords',
          url: Routing.boVaRecords,
          endpoint: '/getVetAttestationsDetails'
        }
      }
    },

    movementSearch: {
      tabs: {
        tab_MovementRange: {
          endpoint: (movementType) => movementType === constants.movementTypeSearch.cancelled ? '/cancelledMovementsList' : '/approvedMovementsList'
        },

        tab_MovementRef: {
          endpoint: () => '/movementDetails'
        },

        tab_PaperId: {
          endpoint: () => '/paperDocument'
        }
      }
    },

    userActivity: {
      eventTypes: {
        all: {
          name: 'all',
          label: 'label.allEventTypes',
          subtitle: 'label.allEventTypes',
          url: Routing.boUserActivityAll,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        animalsAdded: {
          name: 'animalsAdded',
          label: 'label.animalsAdded',
          subtitle: 'label.animalsAdded',
          url: Routing.boUserActivityAnimalsAdded,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        annualInventory: {
          name: 'annualInventory',
          label: 'label.annualInventory',
          subtitle: 'label.annualInventory',
          url: Routing.boUserActivityAnnualInventory,
          endpoint: '/userActivity'
        },
        births: {
          name: 'births',
          label: 'label.births',
          subtitle: 'label.birthsIndividualTagging',
          url: Routing.boUserActivityBirths,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        deaths: {
          name: 'deaths',
          label: 'label.deaths',
          subtitle: 'label.deaths',
          url: Routing.boUserActivityDeaths,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        movements: {
          name: 'movements',
          label: 'label.movements',
          subtitle: 'label.movements',
          url: Routing.boUserActivityMovements,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        movementAmends: {
          name: 'movementAmends',
          label: 'label.movementAmends',
          subtitle: 'label.movementAmendsCorrectedMovements',
          url: Routing.boUserActivityMovementAmends,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        movementReviews: {
          name: 'movementReviews',
          label: 'label.movementReviews',
          subtitle: 'label.movementReviews',
          url: Routing.boUserActivityMovementReviews,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        tagReplacements: {
          name: 'tagReplacements',
          label: 'label.replacementTags',
          subtitle: 'label.replacementTags',
          url: Routing.boUserActivityTaggingReplacement,
          showDateRange: true,
          endpoint: '/userActivity'
        },
        tagApplications: {
          name: 'tagApplications',
          label: 'label.tagApplications',
          subtitle: 'label.tagApplications',
          url: Routing.boUserActivityTaggingApplications,
          showDateRange: true,
          endpoint: '/userActivity'
        }
      }
    }
  },

  claConfig: {
    movement_Date_MaxDaysInFuture_OffMove: 'Movement_Date_MaxDaysInFuture_OffMove',
    movement_Date_MaxDaysInFuture_OnMove: 'Movement_Date_MaxDaysInFuture_OnMove',
    movement_Dates_MaxDaysBetween: 'Movement_Dates_MaxDaysBetween'
  },

  cph: {
    abattoir: 'Abattoir',
    agriculturalHolding: 'Agricultural Holding',
    assemblyCentre: 'Assembly Centre',
    collectionCentre: 'Collection Centre',
    market: 'Market',
    none: 'NON-CPH',
    showground: 'Showground',
    unknown: 'UNKNOWN'
  },

  cphState: {
    dropdown: 'dropdown',
    entry: 'entry',
    readOnly: 'readOnly'
  },

  dateTypes: {
    birth: 'birth',
    death: 'death',
    found: 'found',
    movement: 'movement',
    tagApplication: 'tagApplication'
  },

  dateRangeErrors: {
    birth: 'error.birthDateAboveRange',
    death: 'error.deathDateAboveRange',
    found: 'error.foundDateAboveRange',
    movement: 'error.movementDateAboveRange',
    tagApplication: 'error.taggingDateAboveRange',
    default: 'error.genericDateAboveRange'
  },

  error: {
    addressMessage: 'Request failed with status code 404'
  },

  errorAndWarning: {
    error: 'Error',
    warning: 'Warning'
  },

  filters: {
    inside: 'inside',
    outside: 'outside'
  },

  flags: {
    departure: 'departure',
    destination: 'destination',
    end: 'end',
    from: 'from',
    single: 'single',
    start: 'start',
    to: 'to'
  },

  gender: {
    1: 'unknown',
    2: 'female',
    3: 'male',
    4: 'male',
    5: 'female'
  },

  holdingRegister: {
    identifier: {
      all: 'all'
    },

    table: {
      AdjacentMovements: 'HoldingRegisterAdjacentMovements',
      AnnualInventory: 'HoldingRegisterAnnualInventory',
      Deaths: 'HoldingRegisterDeaths',
      Download: 'HoldingRegisterDownload',
      MovementsOn: 'HoldingRegisterMovementsOn',
      MovementsOff: 'HoldingRegisterMovementsOff',
      TagApplications: 'HoldingRegisterTagApplications',
      TagReplacements: 'HoldingRegisterTagReplacements',
      VaRecords: 'HoldingRegisterVaRecords'
    },

    type: {
      adjacentMovements: 'AdjacentMovements',
      annualInventory: 'AnnualInventory',
      births: 'Births',
      deaths: 'Deaths',
      download: 'Download',
      movementsOn: 'MovementsOn',
      movementsOff: 'MovementsOff',
      tagApplications: 'TagApplications',
      tagReplacements: 'TagReplacements',
      vaRecords: 'VaRecords'
    },

    endpoint: {
      AdjacentMovements: '/adjacentMovements',
      AnnualInventory: '/annualInventory',
      Births: '/holdingRegister',
      Deaths: '/holdingRegister',
      MovementsOn: '/holdingRegister',
      MovementsOff: '/holdingRegister',
      TagApplications: '/holdingRegister',
      TagReplacements: '/holdingRegister',
      VaRecords: '/getVetAttestationsDetails'
    }
  },

  inputType: {
    number: 'number',
    password: 'password',
    text: 'text'
  },

  labelPosition: {
    above: 'above',
    inline: 'inline',
    none: 'none'
  },

  linkTypes: {
    pdfButton: 'pdfButton',
    url: 'url'
  },

  mimeList: [
    { extension: 'avi', mimeType: 'video/x-msvideo' },
    { extension: 'bin', mimeType: 'application/octet-stream' },
    { extension: 'bmp', mimeType: 'image/bmp' },
    { extension: 'bz', mimeType: 'application/x-bzip' },
    { extension: 'bz2', mimeType: 'application/x-bzip2' },
    { extension: 'css', mimeType: 'text/css' },
    { extension: 'csv', mimeType: 'text/csv' },
    { extension: 'doc', mimeType: 'application/msword' },
    { extension: 'docx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extension: 'gz', mimeType: 'application/gzip' },
    { extension: 'gif', mimeType: 'image/gif' },
    { extension: 'htm', mimeType: 'text/html' },
    { extension: 'html', mimeType: 'text/html' },
    { extension: 'ico', mimeType: 'image/vnd.microsoft.icon' },
    { extension: 'ics', mimeType: 'text/calendar' },
    { extension: 'jar', mimeType: 'application/java-archive' },
    { extension: 'jpeg', mimeType: 'image/jpeg' },
    { extension: 'jpg', mimeType: 'image/jpeg' },
    { extension: 'js', mimeType: 'text/javascript' },
    { extension: 'json', mimeType: 'application/json' },
    { extension: 'midi', mimeType: 'audio/midi' },
    { extension: 'mp3', mimeType: 'audio/mpeg' },
    { extension: 'mpeg', mimeType: 'video/mpeg' },
    { extension: 'mpkg', mimeType: 'application/vnd.apple.installer+xml' },
    { extension: 'ods', mimeType: 'application/vnd.oasis.opendocument.spreadsheet' },
    { extension: 'png', mimeType: 'image/png' },
    { extension: 'pdf', mimeType: 'application/pdf' },
    { extension: 'php', mimeType: 'application/x-httpd-php' },
    { extension: 'ppt', mimeType: 'application/vnd.ms-powerpoint' },
    { extension: 'pptx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
    { extension: 'rar', mimeType: 'application/vnd.rar' },
    { extension: 'rtf', mimeType: 'application/rtf' },
    { extension: 'svg', mimeType: 'image/svg+xml' },
    { extension: 'swf', mimeType: 'application/x-shockwave-flash' },
    { extension: 'tar', mimeType: 'application/x-tar' },
    { extension: 'tif', mimeType: 'image/tiff' },
    { extension: 'tiff', mimeType: 'image/tiff' },
    { extension: 'ttf', mimeType: 'font/ttf' },
    { extension: 'txt', mimeType: 'text/plain' },
    { extension: 'wav', mimeType: 'audio/wav' },
    { extension: 'weba', mimeType: 'audio/webm' },
    { extension: 'webm', mimeType: 'video/webm' },
    { extension: 'webp', mimeType: 'image/webp' },
    { extension: 'woff', mimeType: 'font/woff' },
    { extension: 'woff2', mimeType: 'font/woff2' },
    { extension: 'xhtml', mimeType: 'application/xhtml+xml' },
    { extension: 'xls', mimeType: 'application/vnd.ms-excel' },
    { extension: 'xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: 'xml', mimeType: 'application/xml' },
    { extension: 'xul', mimeType: 'application/vnd.mozilla.xul+xml' },
    { extension: 'zip', mimeType: 'application/zip' },
    { extension: '3gp', mimeType: 'video/3gpp' },
    { extension: '3g2', mimeType: 'video/3gpp2' },
    { extension: '7z', mimeType: 'application/x-7z-compressed' }
  ],

  modalActions: {
    add: 'add',
    close: 'close',
    delete: 'delete',
    edit: 'edit',
    view: 'view'
  },

  movement: {
    crossBorderUnsupportedRequestType: [
      requestType.paperMovementAdd
    ]
  },

  movementTypeSearch: {
    standard: 'standard',
    xb: 'xb',
    cancelled: 'cancelled'
  },

  multiItem: {
    row: 'row',
    column: 'column'
  },

  noRecordsToFetch: {
    three: 3
  },

  option: {
    animalsSatisfy: {
      doSatisfy: 'satisfy',
      doNotSatisfy: 'dontSatisfy'
    },

    embryo: {
      yes: 'yes',
      no: 'no'
    },

    exemption: {
      yes: 'yes',
      no: 'no'
    },

    gender: {
      male: 'male',
      female: 'female'
    },

    haulierMapping: {
      receivingKeeper: 'Receiving keeper',
      departureKeeper: 'Departure keeper',
      haulier: 'Haulier'
    },

    holdingReport: {
      allEntries: 'allEntries',
      annualInventory: 'annualInventory',
      deaths: 'deaths',
      offMovements: 'offMovements',
      onMovements: 'onMovements',
      tagApplications: 'tagApplications',
      tagReplacements: 'tagReplacements'
    },

    identification: {
      breedingTags: 'breedingTags',
      slaughterTags: 'slaughterTags'
    },

    knowCPHNumber: {
      cph: 'cph',
      fsa: 'fsa',
      no: 'no',
      unknown: 'unknown',
      yes: 'yes'
    },

    market: {
      green: 'green',
      red: 'red'
    },

    messageStatus: {
      amended: 'AM',
      default: 'DE',
      rejectedMovementReview: 'RMR',
      unconfirmed: 'No',
      unknown: 'UN'
    },

    missingOrFound: {
      missing: 'missing',
      found: 'found'
    },

    movement: {
      business: 'business',
      newMovement: 'newMovement',
      off: 'off',
      on: 'on',
      permit: 'permitMove',
      reviewMovement: 'reviewMovement'
    },

    recordCPH: {
      yes: 'yes',
      no: 'no'
    },

    recordFCI: {
      yes: 'yes',
      no: 'no'
    },

    recordTransportInformation: {
      yes: 'yes',
      no: 'no'
    },

    requestType,

    searchStatus: {
      reportedMoves: 'reportedMoves',
      unconfirmedMoves: 'unconfirmedMoves'
    },

    selectionMethod: {
      batch: 'batch',
      holding: 'holding',
      individual: 'individual',
      manualTagNumbers: 'manualTagNumbers',
      tagNumberRange: 'tagNumberRange',
      uploadFile: 'uploadFile'
    },

    tag: {
      breedingTags: 'breedingTags',
      individualTag: 'individualTag',
      slaughterTags: 'slaughterTags',
      upgradeTags: 'upgradeTags'
    },

    tagMethod: {
      onHolding: 'onHolding',
      selectFromLists: 'selectFromLists',
      tagsManually: 'tagsManually',
      batch: 'batch'
    },

    transporter: {
      departureKeeper: 'departureKeeper',
      destinationKeeper: 'destinationKeeper',
      haulier: 'haulier'
    },

    transporterReverse: {
      'Departure Keeper': 'departureKeeper',
      'Destination Keeper': 'destinationKeeper',
      Haulier: 'haulier',
      DEP: 'departureKeeper',
      DEST: 'destinationKeeper',
      HAULIER: 'haulier',
      1: 'departureKeeper',
      2: 'destinationKeeper',
      3: 'haulier'
    },

    transporterType: {
      departureKeeperType: 'DEP' || 1,
      destinationKeeperType: 'DEST' || 2,
      haulierType: 'HAULIER' || 3
    },

    transporterTypeId: {
      departureKeeperType: 1,
      destinationKeeperType: 2,
      haulierType: 3
    },

    viewTag: {
      viewAllTags: 'viewAllTags',
      viewInvalidTags: 'viewInvalidTags'
    }
  },

  pagination: {
    bottom: 'bottom',
    defaultParams: {
      top: {
        hidePageXofY: true,
        hideShowingXofY: true
      },
      bottom: {
        hidePageXofY: true,
        hideShowingXofY: true,
        hideRecordsPerPage: true
      }
    },
    top: 'top'
  },

  paperDocumentStatus: {
    COMPLETE: 'Complete',
    DELETED: 'Deleted',
    HAS_PROCESSING_ERRORS: 'HasProcessingErrors',
    HAS_VALIDATION_ERRORS: 'HasValidationErrors',
    NOT_AVAILABLE: 'NotAvailable'
  },

  period: {
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    months: 'months',
    seconds: 'seconds',
    weeks: 'weeks',
    years: 'years'
  },

  permitMovesMandatory: {
    destinationExhibition: {
      id: 22,
      name: 'Destination_Exhibition'
    }
  },

  polling: {
    CONFIRM: 'confirm',
    CONFIRM_ERROR: 'confirm_error',
    ENTRY: 'entry',
    PENDING: 'pending',
    ERROR: 'error'
  },

  postType: {
    CONFIRM: 'confirm',
    CREATE: 'create',
    DELETE: 'delete',
    REJECT: 'reject',
    UPDATE: 'update',
    UNDO: 'undo'
  },

  recordedBy: {
    departure: 'Departure',
    destination: 'Destination'
  },

  regions: {
    England: 'en',
    Scotland: 'sc',
    Wales: 'cy'
  },

  sorting: {
    ascending: 'asc',
    descending: 'desc'
  },

  species: {
    animalTypes: {
      UNKNOWN: internalSpeciesLabel.UNKNOWN.toLowerCase(),
      SHEEP: internalSpeciesLabel.SHEEP.toLowerCase(),
      GOATS: internalSpeciesLabel.GOATS.toLowerCase(),
      CATTLE: internalSpeciesLabel.CATTLE.toLowerCase(),
      ALPACAS: internalSpeciesLabel.ALPACAS.toLowerCase(),
      PIGS: internalSpeciesLabel.PIGS.toLowerCase(),
      DEER: internalSpeciesLabel.DEER.toLowerCase(),
      HORSES: internalSpeciesLabel.HORSES.toLowerCase()
    },

    babyName: {
      UNKNOWN: speciesBabyName.UNKNOWN,
      SHEEP: speciesBabyName.SHEEP,
      GOATS: speciesBabyName.GOATS,
      CATTLE: speciesBabyName.CATTLE,
      ALPACAS: speciesBabyName.ALPACAS,
      PIGS: speciesBabyName.PIGS,
      DEER: speciesBabyName.DEER,
      HORSES: speciesBabyName.HORSES
    },

    id: {
      UNKNOWN: speciesId.UNKNOWN,
      SHEEP: speciesId.SHEEP,
      GOATS: speciesId.GOATS,
      CATTLE: speciesId.CATTLE,
      ALPACAS: speciesId.ALPACAS,
      PIGS: speciesId.PIGS,
      DEER: speciesId.DEER,
      HORSES: speciesId.HORSES
    },

    internalLabel: {
      UNKNOWN: internalSpeciesLabel.UNKNOWN,
      SHEEP: internalSpeciesLabel.SHEEP,
      GOATS: internalSpeciesLabel.GOATS,
      CATTLE: internalSpeciesLabel.CATTLE,
      ALPACAS: internalSpeciesLabel.ALPACAS,
      PIGS: internalSpeciesLabel.PIGS,
      DEER: internalSpeciesLabel.DEER,
      HORSES: internalSpeciesLabel.HORSES
    },

    internalList: [
      { id: speciesId.SHEEP, name: internalSpeciesLabel.SHEEP },
      { id: speciesId.GOATS, name: internalSpeciesLabel.GOATS },
      { id: speciesId.CATTLE, name: internalSpeciesLabel.CATTLE },
      { id: speciesId.PIGS, name: internalSpeciesLabel.PIGS },
      { id: speciesId.DEER, name: internalSpeciesLabel.DEER }
    ],

    list: [
      { id: speciesId.UNKNOWN, name: speciesName.UNKNOWN, baby: speciesBabyName.UNKNOWN },
      { id: speciesId.SHEEP, name: speciesName.SHEEP, baby: speciesBabyName.SHEEP },
      { id: speciesId.GOATS, name: speciesName.GOATS, baby: speciesBabyName.GOATS },
      { id: speciesId.CATTLE, name: speciesName.CATTLE, baby: speciesBabyName.CATTLE },
      { id: speciesId.ALPACAS, name: speciesName.ALPACAS, baby: speciesBabyName.ALPACAS },
      { id: speciesId.PIGS, name: speciesName.PIGS, baby: speciesBabyName.PIGS },
      { id: speciesId.DEER, name: speciesName.DEER, baby: speciesBabyName.DEER },
      { id: speciesId.HORSES, name: speciesName.HORSES, baby: speciesBabyName.HORSES }
    ],

    name: {
      UNKNOWN: speciesName.UNKNOWN,
      SHEEP: speciesName.SHEEP,
      GOATS: speciesName.GOATS,
      CATTLE: speciesName.CATTLE,
      ALPACAS: speciesName.ALPACAS,
      PIGS: speciesName.PIGS,
      DEER: speciesName.DEER,
      HORSES: speciesName.HORSES
    },

    order: [
      speciesId.CATTLE,
      speciesId.SHEEP,
      speciesId.GOATS,
      speciesId.PIGS,
      speciesId.DEER
    ]
  },

  states: {
    invalid: 'Invalid',
    reformatted: 'Reformatted',
    valid: 'Valid'
  },

  status: {
    cancelled: 'Cancelled',
    claPending: 'claPending',
    completed_error: 'CompletedError',
    completed_warning: 'CompletedWarning',
    error: 'Error',
    errors: 'Errors',
    pending: 'Pending',
    reported: 'reported',
    processErrors: 'HasProcessingErrors',
    processing: 'Processing',
    success: 'Success',
    unconfirmed: 'unconfirmed',
    validation_error: 'ValidationError',
    warnings: 'Warnings'
  },

  tabs: {
    animalDetails: 'tab_AnimalDetails',
    deaths: 'tab_Deaths',
    donor: 'tab_Donor',
    enterHolding: 'tab_EnterHolding',
    holdings: 'tab_Holdings',
    manualEntry: 'tab_ManualEntry',
    missingAndFound: 'tab_MissingAndFound',
    movementHistory: 'tab_MovementHistory',
    movementRef: 'tab_MovementRef',
    movementRange: 'tab_MovementRange',
    nonCph: 'tab_NonCph',
    onHolding: 'tab_OnHolding',
    paperId: 'tab_PaperId',
    progeny: 'tab_Progeny',
    recentHoldings: 'tab_RecentHoldings',
    recentLocations: 'tab_RecentLocations',
    tagApplications: 'tab_TagApplications',
    tagHistory: 'tab_TagHistory',
    tagReplacements: 'tab_TagReplacements',
    userActivity: 'tab_UserActivity'
  },

  tag: {
    invalid: 'Invalid',
    reformatted: 'Reformatted',
    valid: 'Valid'
  },

  transporter: {
    DEP: 'DEP',
    departureKeeper: 'Departure Keeper',
    DEST: 'DEST',
    destinationKeeper: 'Destination Keeper',
    HAULIER: 'HAULIER',
    haulier: 'Haulier'
  },

  transporterTypes: {
    departureKeeper: ['Departure Keeper', 'DEP', 1],
    destinationKeeper: ['Destination Keeper', 'DEST', 2],
    haulier: ['Haulier', 'HAULIER', 3]
  },

  undoRequestTypes: {
    transfer: 'Transfer',
    correction: 'Correction',
    review: 'Review'
  },

  unknown: 'Unknown',

  url: {
    defraGuidance: 'https://www.gov.uk/guidance/sheep-and-goat-keepers-how-to-keep-a-holding-register',
    defraSupport: 'https://www.livestockinformation.org.uk/lis-support/'
  }
};

export default constants;
