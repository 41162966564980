import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import bff, { isCancel } from 'services/bff';
import classes from 'backOfficeComponents/address/address.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import ErrorLabel from 'components/base/errorLabel/errorLabel';

const Address = ({
  cph,
  errorClassNames,
  format,
  setCphType,
  setCphValid,
  setModal,
  setUserName
}) => {
  const { ready, t } = useTranslation();

  const [address, setAddress] = React.useState(null);
  const [name, setName] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [pending, setPending] = React.useState(false);

  const setData = React.useCallback((inValue) => {
    const display = {
      address: (inData) => {
        if (typeof inData === 'object') {
          // eslint-disable-next-line no-unused-vars
          const { holdingType, holdingTypeId, id, ...inValue } = inData;

          if (helpers.address.isShort(format?.address)) {
            setAddress(helpers.address.format.addressShort(inValue));
          } else if (helpers.address.isLine(format?.address) || helpers.address.isInline(format?.address)) {
            setAddress(helpers.address.format.addressLine(inValue));
          } else {
            setAddress(helpers.address.format.addressFull(inValue));
          }
        } else {
          setAddress([inData]);
        }
      },
      name: (inData) => setName(typeof inData === 'object' ? helpers.address.format.nameFull(inData) : inData),
      type: (inData) => setType(typeof inData === 'object' ? helpers.address.format.typeFull(inData) : inData)
    };

    const otherData = helpers.address.format.addressOnly(inValue) ? '' : inValue;

    display.name(format?.name && !helpers.address.isNone(format.name) ? otherData : '');
    display.type(format?.type && !helpers.address.isNone(format.type) ? otherData : '');
    display.address(format?.address && !helpers.address.isNone(format.address) ? inValue : '');
  }, [format]);

  useEffect(() => {
    const getAddressFromBff = (currentCPH, source) => {
      if (currentCPH?.length > 0) {
        setPending(true);
      }

      const holding = {};

      if (holding && holding.address) {
        setData(holding.address);
        setPending(false);
      } else {
        bff
          .get('/holdingDetails', {
            cancelToken: source.token,
            params: {
              cph: currentCPH,
              queryKeeper: true,
              queryUserByCPH: setUserName && typeof setUserName === 'function'
            }
          })
          .then((res) => {
            if (helpers.response.isValid(res.data, setModal, setPending)) {
              if (res.data.error !== constants.error.addressMessage) {
                const resData = res.data.data[0];
                if (resData?.userByCph !== null) {
                  const hasAddress = resData.address1 || resData.address2 || resData.county || resData.postCode || resData.propertyName || resData.town;

                  if (hasAddress) {
                    setData(resData);
                  } else {
                    setData(constants.address.status.FOUND_WITHOUT_ADDRESS);
                  }
                  if (setCphType && resData.holdingType?.fullName) {
                    setCphType(resData.holdingType.fullName);
                  }
                  if (setCphValid) {
                    setCphValid(true);
                  }
                  if (setUserName) {
                    setUserName(resData.userByCph.id);
                  }
                } else {
                  setData(constants.address.status.CPH_NOT_LINKED);
                }
              } else {
                setData(constants.address.status.NOT_FOUND);
                if (setCphValid) {
                  setCphValid(false);
                }
              }

              setPending(false);
            }
          })
          .catch((error) => {
            if (!isCancel(error)) {
              setPending(false);
              setData(constants.address.status.ERROR);
              if (setCphValid) {
                setCphValid(false);
              }
              errors.BFF(error, setModal);
            }
          });
      }
    };

    const source = axios.CancelToken.source();

    if (!cph || cph?.length === 0) {
      setAddress(null);
      setName(null);
      setType(null);
    }

    if (cph?.length >= config.LENGTH_HOLDING_EU) {
      if (cph.length === config.LENGTH_HOLDING_EU || cph.length === config.LENGTH_HOLDING_CPH) {
        getAddressFromBff(cph, source);
      } else {
        setAddress([constants.address.status.NOT_FOUND]);
        setName(null);
        setType(null);
      }
    } else {
      if (setCphValid) {
        setCphValid(false);
      }
      setData('');
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cph]);

  return (
    <>
      {ready &&
        <>
          {pending &&
            <>
              {helpers.address.isInline(format?.address) &&
                <ErrorLabel
                  classNames={errorClassNames}
                  isWarning={true}
                  label={t(constants.address.status.FETCH)}
                />
              }
              {!helpers.address.isInline(format?.address) &&
                <ErrorLabel
                  classNames={errorClassNames}
                  isWarning={true}
                  label={t(constants.address.status.FETCH)}
                />
              }
            </>
          }

          {!pending &&
            <>
              {type?.length > 0 && !helpers.address.withErrorAddress(type) && !helpers.address.isStatusNotFound(address[0]) &&
                <p className={classes.type}>{t(type)}</p>
              }
              {helpers.address.isNoNameFound(name) &&
                <ErrorLabel
                  classNames={[helpers.address.isInline(format?.address) ? 'inline' : null]}
                  isWarning={true}
                  label={constants.address.status.NO_NAME}
                />
              }
              {name?.length > 0 && !helpers.address.isNoNameFound(name) && !helpers.address.withErrorAddress(name) && !helpers.address.isStatusError1(name) && !helpers.address.isStatusNotFound(address[0]) &&
                <>
                  {helpers.address.isInline(format?.address) &&
                    <span className={classes.name}>{t(name)}</span>
                  }
                  {!helpers.address.isInline(format?.address) &&
                    <p className={classes.name}>{t(name)}</p>
                  }
                </>
              }

              {address?.length > 0 && address[0] !== '' &&
                <>
                  {helpers.address.withErrorAddress(address[0]) &&
                    <>
                      {helpers.address.isInline(format?.address) &&
                        <ErrorLabel
                          classNames={errorClassNames}
                          isWarning={!helpers.address.isCphNotLinked(address[0]) && !helpers.address.isStatusNotFound(address[0])}
                          label={t(address[0])}
                        />
                      }
                      {!helpers.address.isInline(format?.address) &&
                        <ErrorLabel
                          classNames={errorClassNames}
                          isWarning={!helpers.address.isCphNotLinked(address[0]) && !helpers.address.isStatusNotFound(address[0])}
                          label={t(address[0])}
                        />
                      }
                    </>
                  }

                  {helpers.address.withAddress(address[0]) &&
                    <>
                      {helpers.address.isInline(format?.address) &&
                        <address className={classes.address}>{address[0] ? t(he.decode(address[0].trim())) : ''}</address>
                      }
                      {!helpers.address.isInline(format?.address) &&
                        <>
                          {address.map((line, index) => (
                            <div className={classes.addressLine} key={index}>{line ? he.decode(line) : ''}</div>
                          ))}
                        </>
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </>
      }
    </>
  );
};

Address.propTypes = {
  cph: PropTypes.string,
  errorClassNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  format: PropTypes.exact({
    address: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.short,
      constants.address.format.line,
      constants.address.format.inline,
      constants.address.format.none
    ]),
    name: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.inline,
      constants.address.format.none
    ]),
    type: PropTypes.oneOf([
      constants.address.format.full,
      constants.address.format.none
    ])
  }),
  setCphType: PropTypes.func,
  setCphValid: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setUserName: PropTypes.func
};

export default Address;
