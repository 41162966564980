import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import axios from 'axios';
import bff, { bffStatus, isCancel } from 'services/bff';
import classes from 'backOfficeComponents/sections/verifyCustomerInformation/verifyCustomerInformation.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import he from 'he';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import Routing from 'routing';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import DisplayValue from 'backOfficeComponents/base/displayValue/displayValue';
import SectionTitleBar from 'backOfficeComponents/base/sectionTitleBar/sectionTitleBar';
import TextEntry from 'components/base/textEntry/textEntry';

const sprintf = require('util').format;

const VerifyCustomerInformation = ({
  data,
  setModal
}) => {
  const { ready, t } = useTranslation();
  const history = useHistory();

  const [keeperEmailOld, setKeeperEmailOld] = useState(data.keeperEmail);
  const [keeperEmailNew, setKeeperEmailNew] = useState(data.keeperEmail);
  const [keeperEmailValid, setKeeperEmailValid] = useState(true);
  const [holdingId, setHoldingId] = useState(null);
  const [keeperAddressPK, setKeeperAddressPK] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [pending, setPending] = useState(false);
  const [singleBusinessIdentifier, setSingleBusinessIdentifier] = useState(data.singleBusinessIdentifier);
  const [partyIdError, setPartyIdError] = useState(false);

  const validateEmail = (email) => {
    const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email.match(validEmailFormat);
  };

  const saveEmail = () => {
    if (partyId && partyId !== null) {
      setPending(true);
      bff
        .post('/userEmail', {
          poll: config.POLLS_ENABLED,
          holdingId,
          keeperAddressPK,
          partyId,
          email: keeperEmailNew,
          singleBusinessIdentifier
        })
        .then((res) => {
          setKeeperEmailOld(keeperEmailNew);
          setPending(false);

          const pollingStatus = res.data?.poll?.requestStatus;
          const modalMessages = {
            [constants.status.error]: 'label.customerEmailUpdateError',
            [constants.status.pending]: 'label.customerEmailUpdatePending',
            [constants.status.success]: 'label.customerEmailUpdateSuccess'
          };

          if (!bffStatus.isSuccess(pollingStatus)) {
            setKeeperEmailNew(keeperEmailOld);
          }
          setModal({
            labelCancel: 'button.ok',
            modalMessage: modalMessages[pollingStatus],
            modalTitle: 'label.customerEmailUpdate'
          });
        })
        .catch((error) => {
          setPending(false);
          errors.BFF(error, setModal);
        });
    } else {
      setPartyIdError(true);
      setModal({
        labelCancel: 'button.ok',
        modalMessage: 'label.partyIdError.message',
        modalTitle: 'label.partyIdError.title'
      });
    }
  };

  const handleChanges = {
    email: (email) => {
      setKeeperEmailNew(email);

      if (!validateEmail(email)) {
        setKeeperEmailValid(false);
      } else {
        setKeeperEmailValid(true);
      }
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (pending) {
      bff
        .get('/holdingsIntegration', {
          cancelToken: source.token,
          params: {
            cph: data.cph
          }
        })
        .then((res) => {
          if (helpers.response.isValid(res.data, setModal, setPending)) {
            setPending(false);
            setHoldingId(res.data.holdingId);
            setKeeperAddressPK(res.data.keeperAddressPK);
            setPartyId(res.data.partyId);
            setSingleBusinessIdentifier(res.data.singleBusinessIdentifier);
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setPending(false);
            errors.BFF(error, setModal);
          }
        });
    }

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pending]);

  useEffect(() => {
    setPending(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contactNumber = [data.telephoneNumber, data.mobilePhone].filter(Boolean);
  return (
    <>
      {ready &&
        <>
          <Prompt
            message={(params) => {
              return (!storeService.session.get.idle() && keeperEmailNew !== keeperEmailOld && !partyIdError && params.pathname.substring(0, Routing.boVerifyCustomer.length) !== Routing.boEditCustomer) ? t('warning.unsavedChanges2') : true;
            }}
          />
          <div className="pageContent">
            <SectionTitleBar
              icon="bi bi-person-check-fill"
              id="verifyCustomerSearchTitle"
              title="boApp.label.verifyCustomerInformation"
            />
            <div className={classes.verifyCustomerInformation}>
              <div className={classes.holdingDetails}>
                <DisplayValue
                  id="holdingCPH"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.holdingCph"
                  value={data.id ?? data.cph}
                />
                <DisplayValue
                  id="holdingName"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.holdingName"
                  value={data?.holdingName ? he.decode(data.holdingName) : ''}
                />
                <DisplayValue
                  id="holdingAddress"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.holdingAddress"
                  value={[
                    data?.address1 ? he.decode(data.address1) : '',
                    data?.address2 ? he.decode(data.address2) : '',
                    data.town,
                    data.county
                  ]}
                />
                <DisplayValue
                  id="holdingPostcode"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.holdingPostcode"
                  value={data.postCode ?? data.holdingPostcode}
                />
                <DisplayValue
                  id="holdingFlock"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.holdingFlock"
                  value={data?.batchNumbers ? data.batchNumbers.map((batch) => batch.number) : t('label.na')}
                />
              </div>
              <div className={classes.keeperDetails}>
                <DisplayValue
                  id="keeperName"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.keeperName"
                  value={data?.keeperName ? he.decode(data.keeperName) : ''}
                />
                <DisplayValue
                  id="keeperTelephone"
                  labelPosition={constants.labelPosition.inline}
                  labelText="boApp.label.keeperTelephone"
                  value={contactNumber?.length > 1
                    ? sprintf('%s (%s)', contactNumber[0], contactNumber[1])
                    : (contactNumber?.length > 0 ? contactNumber[0] : null)
                  }
                />
                <TextEntry
                  classNames={['bo-inline']}
                  disabled={pending}
                  id="keeperEmail"
                  label="boApp.label.keeperEmail"
                  maxLength={config.LENGTH_EMAIL}
                  name="keeperEmail"
                  onChange={(event) => handleChanges.email(event.target.value)}
                  type="email"
                  value={keeperEmailNew}
                />
                <Button
                  buttonType="primary"
                  classNames={['bo-keeper-email']}
                  disabled={pending || keeperEmailNew === keeperEmailOld || !keeperEmailValid}
                  label="button.saveChanges"
                  onClick={saveEmail}
                />
              </div>
            </div>
            <div className="actions">
              <div className="left" />
              <div className="right">
                <Button
                  buttonType="tertiary"
                  label="button.exit"
                  onClick={() => history.push(Routing.boVerifyCustomer)}
                />
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

VerifyCustomerInformation.propTypes = {
  data: PropTypes.object,
  setModal: PropTypes.func.isRequired
};

export default VerifyCustomerInformation;
